import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-accessible-accordion';
import { accordion } from '../../../global/global.css';
import { AccordionCustomItem } from '../../../components/functional/new_components/AccordionItem/AccordionItem';
import { WriteToUsSection } from '../new_write_to_us_section/WriteToUsSection';
import { AboveTitle, Title, Wrapper } from '../../../global/new-global-components';
import { FaqSectionWrapper } from './FaqSection.styles';

export const FaqSection = () => {
  const { t } = useTranslation();
  const faqs = [
    {
      title: t('home_FaqQuestionOne'),
      text: t('home_FaqAnswerOne'),
      img: '',
    },
    {
      title: t('home_FaqQuestionTwo'),
      text: t('home_FaqAnswerTwo'),
      img: '',
    },
    {
      title: t('home_FaqQuestionThree'),
      text: t('home_FaqAnswerThree'),
      img: '',
    },
    {
      title: t('home_FaqQuestionFour'),
      text: t('home_FaqAnswerFour'),
      img: '',
    },
  ];

  return (
    <FaqSectionWrapper>
      <Wrapper backgroundColor="#e9e9eb" padding="100px 300px" position="relative" overflow="hidden" className="faq-section section">
        <AboveTitle>{t('home_FaqLinkSubHeading')}</AboveTitle>
        <Title textAlign="center" maxWidth="1280px" margin="0px auto 100px auto">
          {t('home_FaqLinkHeading')}
        </Title>

        <Wrapper className="faq-and-write-to-us-wrapper">
          <Accordion allowMultipleExpanded={true} css={accordion}>
            {faqs.map((faq, index) => {
              return <AccordionCustomItem key={index} id={index} faq={faq} />;
            })}
          </Accordion>
          <WriteToUsSection />
        </Wrapper>
      </Wrapper>
    </FaqSectionWrapper>
  );
};
