import React, { useEffect, useRef, useState } from 'react';

// @ts-ignore
import { gsap, MotionPathPlugin, ScrollTrigger } from 'gsap/all';
import { useTranslation } from 'react-i18next';
import { FlexCol, FlexWrapperStart, Img } from '../../../global/new-global-components';
import CardCarousel from './new_card_component/card-component';

const RoadWithCarMobileComponent = () => {
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
  const { t, i18n } = useTranslation();
  const LANG = i18n.language;

  const firstImage = t('home_howItWorksFirstStepPhoneScreen');
  const [phoneImage, setPhoneImage] = useState(firstImage);
  const [cardCarouselIndex, setCardCarouselIndex] = useState(0);
  const carImage = useRef<any>(null);

  const secondImage = t('home_howItWorksSecondStepPhoneScreen');
  const thirdImage = t('home_howItWorksThirdStepPhoneScreen');
  const fourthImage = t('home_howItWorksFourthStepPhoneScreen');

  useEffect(() => {
    const tl = gsap.timeline();

    gsap.to('#rect', {
      motionPath: {
        path: '#path',
        align: '#path',
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      scrollTrigger: {
        trigger: '#how-it-works',
        start: '-46px top',
        end: '+=600',
        scrub: 1,
        pin: true,
        anticipatePin: 1,
        // pinSpacing: false,
        markers: false,
        onUpdate: (self: any) => {
          if (self.progress > 0.3) {
            if (carImage.current) {
              carImage.current.setAttribute('href', '/new-images/towing.png');
              carImage.current.style.height = '135px';
              carImage.current.style.width = '220px';
            }
          } else {
            if (carImage.current) {
              carImage.current.setAttribute('href', '/new-images/autic.png');
              carImage.current.style.height = '130px';
              carImage.current.style.width = '170px';
            }
          }
          if (self.progress < 0.05) {
            setPhoneImage(firstImage);
            setCardCarouselIndex(0);
          } else if (self.progress > 0.05 && self.progress < 0.25) {
            setCardCarouselIndex(1);
            setPhoneImage(secondImage);
          } else if (self.progress > 0.25 && self.progress < 0.6) {
            setCardCarouselIndex(2);
            setPhoneImage(thirdImage);
          } else if (self.progress > 0.6) {
            setCardCarouselIndex(3);
            setPhoneImage(fourthImage);
          }
        },
      },
    });

    tl.to('#phone', { duration: 0.2, translateY: -50 });
    return () => setCardCarouselIndex(0);
  }, []);

  return (
    <>
      <svg id="road-with-car" viewBox="0 0 1255 1729" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="path"
          d="M344 20V377.821C344 397.088 334.748 415.182 319.129 426.462L44.8706 624.538C29.2518 635.818 20 653.912 20 673.179V1001"
          stroke="#1E1E23"
          strokeOpacity="0.1"
          strokeWidth="40"
          strokeLinecap="round"
        />
        <path
          d="M344 23V372.708C344 395.186 333.206 416.296 314.984 429.456L49.0156 621.544C30.7938 634.705 20 655.815 20 678.292V1003"
          stroke="#3B3B3C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeDasharray="6 6"
        />
        <g id="rect">
          <image
            ref={carImage}
            id="car-img"
            style={{ transform: 'rotate(180deg)', height: '130px', width: '140px', marginTop: '-60px' }}
            href="/new-images/autic.png"
          />
        </g>
      </svg>
      <FlexWrapperStart overflow="initial" padding="0px 0px 300px 0px">
        <FlexCol className="flex-col" textAlign="center" width="50%">
          <Img width="400px" position="relative" marginBottom="-150px" data-tilt id="phone" className="phone" src={phoneImage} />
        </FlexCol>

        <CardCarousel currentIndex={cardCarouselIndex} />
      </FlexWrapperStart>
    </>
  );
};

export default RoadWithCarMobileComponent;
