import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import moment from 'moment';
import { sliceByWord } from '../../../utils/sliceByWord';
import {
  FeaturedPostImage,
  FeaturedPostLink,
  FeaturedPostText,
  FeaturedPostTitle,
  PostDescription,
  PostTextWrapper,
  PostTitle,
  Press,
  PressSectionWrapper,
} from './press.styles';
import Img from 'gatsby-image';
import {
  AboveTitle,
  BadgeGray,
  BadgeLightGray,
  BadgeOrange,
  FlexCol,
  FlexWrapper,
  FlexWrapperStart,
  ImgWrapper,
  Title,
  VozziButton,
} from '../../../global/new-global-components';
import { BlogPostI } from 'pages_/blog/BlogPost.interface';

export const PressSection = (props: any) => {
  const { posts } = props;
  const { t } = useTranslation();

  posts.sort(function (a: string, b: string) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <PressSectionWrapper>
      <Press className="section press-section">
        <AboveTitle textTransform="normal">{t('home_VozziInTheMediaSubHeading')}</AboveTitle>
        <Title marginBottom="80px">
          <Trans i18nKey={'home_VozziInTheMediaHeading'}>
            part 1<span className="break-on-md-and-lg">part 2</span>
          </Trans>
        </Title>
        <Link to={posts[0].url}>
          <FlexWrapper className="mobile-flex-reverse featured-post" overflow="hidden" backgroundColor="#1e1e23" borderRadius="20px">
            <FlexCol className="flex-col flex-col-text featured-post-text-inner" width="45%" padding="50px 60px">
              <FlexWrapperStart className="mobile-stay-flex">
                <BadgeOrange>{posts[0].categories[0].slug}</BadgeOrange>
                <BadgeGray>{moment(posts[0].date).format('DD.MM.YYYY')}</BadgeGray>
              </FlexWrapperStart>
              <FeaturedPostTitle dangerouslySetInnerHTML={{ __html: posts[0].title }}></FeaturedPostTitle>
              <FeaturedPostText
                dangerouslySetInnerHTML={{ __html: sliceByWord(posts[0].excerpt as string, 200).replace(/<[^>]*>?/gm, '') }}
              ></FeaturedPostText>
              <FeaturedPostLink href={posts[0].url}>{t('home_VozziInTheMediaPostOneReadAll')}</FeaturedPostLink>
            </FlexCol>
            <FlexCol className="flex-col" width="55%">
              <FeaturedPostImage src={posts[0].featuredImg.src} />
            </FlexCol>
          </FlexWrapper>
        </Link>

        <FlexWrapper className="posts-wrapper" margin="20px 0px" flexWrap="wrap">
          {posts
            .filter((post: BlogPostI, index: number) => index < 5)
            .map((post: BlogPostI, index: number) => (
              <React.Fragment key={index}>
                {index !== 0 ? (
                  <FlexCol
                    className="single-post"
                    marginBottom="20px"
                    borderRadius="20px"
                    backgroundColor="#f4f4f4"
                    margin="40px 0px"
                    width="100%"
                  >
                    <Link to={post.url}>
                      <FlexWrapperStart className="mobile-stay-flex">
                        <ImgWrapper className="single-post-image-wrapper" overflow="hidden" position="relative" maxWidth="40%">
                          <Img fluid={post.featuredImg} />
                        </ImgWrapper>
                        <PostTextWrapper>
                          <FlexWrapperStart className="mobile-stay-flex">
                            <BadgeOrange height="20px" marginBottom="0" lineHeight="20px">
                              {t('home_VozziInTheMediaBadgeTwoOrange')}
                            </BadgeOrange>
                            <BadgeLightGray height="20px" marginBottom="0" lineHeight="20px">
                              {t('home_VozziInTheMediaBadgeTwoGrey')}
                            </BadgeLightGray>
                          </FlexWrapperStart>
                          <PostTitle dangerouslySetInnerHTML={{ __html: post.title }}></PostTitle>
                          <PostDescription
                            dangerouslySetInnerHTML={{ __html: sliceByWord(post.excerpt as string, 200).replace(/<[^>]*>?/gm, '...') }}
                          ></PostDescription>
                        </PostTextWrapper>
                      </FlexWrapperStart>
                    </Link>
                  </FlexCol>
                ) : null}
              </React.Fragment>
            ))}
        </FlexWrapper>

        <VozziButton style={{ margin: '0 auto' }}>{t('home_VozziInTheMediaCTAMoreNews')}</VozziButton>
      </Press>
    </PressSectionWrapper>
  );
};
