import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { COUNTRY } from '../../../configs/env';
import TestimonialBox from './testimonial-box/TestimonialBox';
import { VideoModal } from '../../../components/layout/VideoModal/VideoModal';
import Slider from 'react-slick';
import ShareImpression from '../new_share_impression_section/share_impression';
import { AboveTitle, Title, Wrapper } from '../../../global/new-global-components';
import { TestimonialsSectionWrapper } from './testimonials.styles';

interface TestimonialsProps {
  sliderOnly?: boolean;
}

export const TestimonialsSection = ({ sliderOnly }: TestimonialsProps) => {
  const { t } = useTranslation();

  const testimonialData = [
    {
      title: t('home_VideoTestimonialTitleOgnjenPerovic'),
      description: t('home_VideoTestimonialOgnjenPerovic'),
      // date: '21.07.2021.',
      mark: 5,
      name: 'Ognjen Perović',
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-ognjen-petrovic.png',
      modalVideo: '/video/video-ognjen-petrovic.mp4',
      subtitle: `/subtitles/${COUNTRY}/video-ognjen-perovic.vtt`,
    },
    {
      title: t('home_VideoTestimonialTitleLjubomirIvanis'),
      description: t('home_VideoTestimonialLjubomirIvanis'),
      name: 'Ljubomir Ivaniš',
      mark: 4,
      // date: '21.07.2021.',
      videoPoster: 'testimonialPosters/poster-ljubomir-ivanes.png',
      modalVideo: '/video/video-ime-i-prezime.mp4',
      subtitle: `/subtitles/${COUNTRY}/video-ljubomir-ivanis.vtt`,
    },
    {
      title: t('home_VideoTestimonialTitleNenadPaunovic'),
      description: t('home_VideoTestimonialNenadPaunovic'),
      name: 'Nenad Paunović',
      mark: 4,
      // date: '21.07.2021.',
      videoPoster: 'testimonialPosters/poster-nenad-paunovic.jpg',
      modalVideo: '/video/video-nenad-paunovic-v2.mp4',
      subtitle: `/subtitles/${COUNTRY}/video-nenad-paunovic.vtt`,
    },
    {
      title: t('home_VideoTestimonialTitleLazarSklopic'),
      description: t('home_VideoTestimonialLazarSklopic'),
      name: 'Lazar Sklopić',
      mark: 4,
      // date: '21.07.2021.',
      videoPoster: 'testimonialPosters/poster-lazar-sklopic.jpg',
      modalVideo: '/video/video-lazar-sklopic-v2.mp4',
      subtitle: `/subtitles/${COUNTRY}/video-lazar-sklopic.vtt`,
    },
    {
      title: t('home_VideoTestimonialTitleSlavkoMakalj'),
      description: t('home_VideoTestimonialSlavkoMakalj'),
      name: 'Slavko Makaji',
      mark: 4,
      // date: '21.07.2021.',
      videoPoster: 'testimonialPosters/poster-slavko-makaji.jpg',
      modalVideo: '/video/video-slavko-makaji-v2.mp4',
      subtitle: `/subtitles/${COUNTRY}/video-slavko-makaji.vtt`,
    },
    {
      name: 'Milovan Ivanovic',
      title: t('home_VideoTestimonialTitleMilovanIvanovic'),
      description: t('home_VideoTestimonialMilovanIvanovic'),
      mark: 4,
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-milovan-ivanovic.jpg',
      modalVideo: '/video/video-milovan-ivanovic-v2.mp4',
      subtitle: `/subtitles/${COUNTRY}/video-milovan-ivanovic.vtt`,
    },
    {
      name: 'Ivan i Branka',
      title: t('home_TestimonialsTitleIvaniBranka'),
      description: t('home_TestimonialsIvaniBranka'),
      mark: 4,
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-ivan-i-branka.jpg',
      modalVideo: '/video/video-ivan-i-branka--opt-v2.mp4',
      subtitle: '',
    },
    {
      name: 'Života Nešković',
      title: t('home_TestimonialsTitleZivotaNeskovic'),
      description: t('home_TestimonialsZivotaNeskovic'),
      mark: 5,
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-zivota-neskovic.jpg',
      modalVideo: '/video/video-zivota-neskovic-v2.mp4',
      subtitle: '',
    },
    {
      name: 'Tihomir Milenković',
      title: t('home_TestimonialsTitleTihomirMilenkovic'),
      description: t('home_TestimonialsTihomirMilenkovic'),
      mark: 5,
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-tihomir-milenkovic.jpg',
      modalVideo: '/video/video-tihomir-milenkovic-v2.mp4',
      subtitle: '',
    },
    {
      name: 'Vesna Nešković',
      title: t('home_TestimonialsTitleVesnaNeskovic'),
      description: t('home_TestimonialsVesnaNeskovic'),
      mark: 5,
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-vesna-neskovic.jpg',
      modalVideo: '/video/video-vesna-neskovic-v2.mp4',
      subtitle: '',
    },
    {
      name: 'Vladimir Andrejević',
      title: t('home_TestimonialsTitleVladimirAndrejevic'),
      description: t('home_TestimonialsVladimirAndrejevic'),
      mark: 5,
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-vladimir-andrejevic.jpg',
      modalVideo: '/video/video-vladimir-andrejevic-v2.mp4',
      subtitle: '',
    },
    {
      name: 'Zlatko Zečević',
      title: t('home_TestimonialsTitleZlatkoZecevic'),
      description: t('home_TestimonialsZlatkoZecevic'),
      mark: 5,
      avatarImg: 'testimonialAvatars/vozzi-avatar@3x.png',
      videoPoster: 'testimonialPosters/poster-zlatko-zecevic.jpg',
      modalVideo: '/video/video-zlatko-zecevic--opt-v2.mp4',
      subtitle: '',
    },
    {
      name: 'Nenad Paunović',
      description: t('home_TestimonialsNenadPaunovic'),
      avatarImg: 'testimonialAvatars/nenad-paunovic.jpg',
      content: 'home_TestimonialsNenadPaunovic',
      mark: 5,
      date: '19.08.2021.',
    },
    {
      name: 'Lazar Sklopić',
      description: t('home_TestimonialsLazarSklopic'),
      avatarImg: 'testimonialAvatars/lazar-sklopic.jpg',
      content: 'home_TestimonialsLazarSklopic',
      mark: 5,
      date: '12.08.2021.',
    },
    {
      name: 'Mladen Stojanović',
      description: t('home_TestimonialsMladenStojanovic'),
      avatarImg: 'testimonialAvatars/mladen-stojanovic@2x.png',
      content: 'home_TestimonialsMladenStojanovic',
      mark: 5,
      date: '21.07.2018.',
    },
    {
      name: 'Slavko Makaji',
      description: t('home_TestimonialsSlavkoMakaji'),
      avatarImg: 'testimonialAvatars/slavko-makaji.jpg',
      content: 'home_TestimonialsSlavkoMakaji',
      mark: 5,
      date: '09.07.2021.',
    },
    {
      name: 'Milovan Ivanović',
      description: t('home_TestimonialsMilovanIvanovic'),
      avatarImg: 'testimonialAvatars/milovan-ivanovic.jpg',
      content: 'home_TestimonialsMilovanIvanovic',
      mark: 5,
      date: '23.06.2021.',
    },
    {
      name: 'Miljan Plamenac',
      description: t('home_TestimonialsMiljanPlamenac'),
      avatarImg: 'testimonialAvatars/miljan-plamenac@2x.png',
      content: 'home_TestimonialsMiljanPlamenac',
      mark: 4.5,
      date: '07.07.2017.',
    },
    {
      name: 'Maja Stipanović',
      description: t('home_TestimonialsMajaStipanovic'),
      avatarImg: 'testimonialAvatars/maja-stipanovic.jpg',
      content: 'home_TestimonialsMajaStipanovic',
      mark: 5,
      date: '18.03.2018.',
    },
    {
      name: 'Darko Vidić',
      description: t('home_TestimonialsDarkoVidic'),
      avatarImg: 'testimonialAvatars/darko-vidic.jpg',
      content: 'home_TestimonialsDarkoVidic',
      mark: 5,
      date: '27.01.2018.',
    },
    {
      name: 'Nenad Rankov',
      description: t('home_TestimonialsNenadRankov'),
      avatarImg: 'testimonialAvatars/nenad-rankov.jpg',
      content: 'home_TestimonialsNenadRankov',
      mark: 4.5,
      date: '20.05.2018.',
    },
    {
      name: 'Vesna Nešković',
      description: t('home_TestimonialsVesnaNeskovic'),
      avatarImg: 'testimonialAvatars/vesna-neskovic.png',
      content: 'home_TestimonialsVesnaNeskovic',
      mark: 5,
      date: '31.07.2019.',
    },
    {
      name: 'Tihomir Milenković',
      description: t('home_TestimonialsTihomirMilenkovic'),
      avatarImg: 'testimonialAvatars/tihomir-milenkovic.png',
      content: 'home_TestimonialsTihomirMilenkovic',
      mark: 5,
      date: '02.10.2019.',
    },
    {
      name: 'Života Nešković',
      description: t('home_TestimonialsZivotaNeskovic'),
      avatarImg: 'testimonialAvatars/zivota-neskovic.png',
      content: 'home_TestimonialsZivotaNeskovic',
      mark: 5,
      date: '12.12.2019.',
    },
    {
      name: 'Vladimir Andrejević',
      description: t('home_TestimonialsVladimirAndrejevic'),
      avatarImg: 'testimonialAvatars/vladimir-andrejevic.png',
      content: 'home_TestimonialsVladimirAndrejevic',
      mark: 5,
      date: '11.07.2019.',
    },
    {
      name: 'Zlatko Zečević',
      description: t('home_TestimonialsZlatkoZecevic'),
      avatarImg: 'testimonialAvatars/zlatko-zecevic.png',
      content: 'home_TestimonialsZlatkoZecevic',
      mark: 5,
      date: '09.06.2019.',
    },
    {
      name: 'Ivan i Branka',
      description: t('home_TestimonialsIvaniBranka'),
      avatarImg: 'testimonialAvatars/ivan-branka.png',
      content: 'home_TestimonialsIvaniBranka',
      mark: 5,
      date: '09.03.2020.',
    },
  ];

  const { testimonialAvatars, testimonialPosters } = useStaticQuery(
    graphql`
      query {
        testimonialAvatars: allFile(filter: { relativeDirectory: { eq: "testimonialAvatars" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        testimonialPosters: allFile(filter: { relativeDirectory: { eq: "testimonialPosters" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  const [isOpen, setIsOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const [subtitleSrc, setSubtitleSrc] = useState('');

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1 = [] as any;
  let slider2 = [] as any;

  const hideModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (videoSrc: string, subtitleSrc: string): void => {
    setVideoSrc(videoSrc);
    setSubtitleSrc(subtitleSrc);
    setIsOpen(true);
  };

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  function Arrow(props: any) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  function PrevSvgArrow(props: any) {
    const { className, onClick } = props;
    return (
      <img
        style={{
          height: 54,
          width: 54,
          marginRight: '10px',
          opacity: onClick === null ? 0.25 : 1,
          cursor: onClick === null ? 'auto' : 'pointer',
        }}
        src="/img/slider-new-previous.svg"
        className={className}
        onClick={onClick}
      />
    );
  }

  function NextSvgArrow(props: any) {
    const { className, onClick } = props;
    return (
      <img
        style={{
          height: 54,
          width: 54,
          marginLeft: '10px',
          opacity: onClick === null ? 0.25 : 1,
          cursor: onClick === null ? 'auto' : 'pointer',
        }}
        src="/img/slider-new-next.svg"
        className={className}
        onClick={onClick}
      />
    );
  }

  const countriesDoNotShowWithoutSubtitles = ['bg', 'ro', 'mk'];

  const testimonialComponents = testimonialData.map((testimonial, index) => {
    const avatar = testimonialAvatars.edges.find(({ node }: any) => node.relativePath === testimonial.avatarImg);
    const poster = testimonialPosters.edges.find(({ node }: any) => node.relativePath === testimonial.videoPoster);
    if (
      (testimonial.subtitle !== undefined && testimonial.subtitle !== '') ||
      testimonial.subtitle === undefined ||
      !countriesDoNotShowWithoutSubtitles.includes(COUNTRY)
    ) {
      return (
        <TestimonialBox
          key={index}
          avatarImg={avatar && avatar.node.childImageSharp.fluid}
          title={testimonial.title}
          name={testimonial.name}
          description={testimonial.description}
          videoPoster={poster && poster.node.childImageSharp.fluid}
          date={testimonial.date}
          mark={testimonial.mark}
          onClick={
            (testimonial.modalVideo, testimonial.subtitle ? (): void => openModal(testimonial.modalVideo, testimonial.subtitle) : undefined)
          }
        />
      );
    }
  });

  const countTestimonialComponents = testimonialComponents.length;

  const arr = [];
  for (let i = 1; i < countTestimonialComponents + 1; i++) {
    arr.push(i);
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    height: 500,
    arrows: false,
    variableWidth: true,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <Arrow className="slick-arrow slick-prev" />,
    nextArrow: <Arrow className="slick-arrow slick-next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsForPaginationSlider = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    infinite: false,
    arrows: true,
    focusOnSelect: false,
    variableWidth: true,
    prevArrow: <PrevSvgArrow />,
    nextArrow: <NextSvgArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          variableWidth: false,
          prevArrow: <PrevSvgArrow />,
          nextArrow: <NextSvgArrow />,
        },
      },
    ],
  };

  return (
    <TestimonialsSectionWrapper>
      <Wrapper backgroundColor="#F4F4F4">
        <Wrapper padding="60px 300px 60px 300px" textAlign="center" margin="0 auto" className="testimonials-section section">
          <AboveTitle>{t('home_TestimonialsSubHeading')}</AboveTitle>
          <Title margin="0 auto" maxWidth="1040px" textAlign="center">
            {t('home_TestimonialsHeading')}
          </Title>
        </Wrapper>
        <Wrapper className="testimonials-slider-wrapper">
          <VideoModal show={isOpen} onHide={hideModal} subtitleSrc={subtitleSrc} videoSrc={videoSrc} />
          <Slider
            className="testimonials-slider testimonials-first-slider"
            asNavFor={nav2 || undefined}
            ref={(slider) => (slider1 = slider)}
            {...settings}
          >
            {testimonialComponents}
          </Slider>
          <Slider
            className="testimonials-second-slider second-slider testimonials-slider testimonialsArrowsWrapper"
            asNavFor={nav1 || undefined}
            ref={(slider) => (slider2 = slider)}
            {...settingsForPaginationSlider}
          >
            {arr.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </Slider>
        </Wrapper>

        <Wrapper
          padding="0px 300px 60px 300px"
          backgroundColor="#F4F4F4"
          textAlign="center"
          margin="0px auto"
          className="testimonials-section section"
        >
          <ShareImpression />
        </Wrapper>
      </Wrapper>
    </TestimonialsSectionWrapper>
  );
};
