import { css } from '@emotion/core';
import { COUNTRY } from '../configs/env';

export const container = css`
  max-width: 1300px;
  margin: 0 auto;
`;

export const accordion = css`
  border: none;
  .accordion__button {
    background-color: transparent;
  }
  .accordion__panel {
    padding: 0 0 20px 0;
  }
`;

export const accordionItem = css`
  cursor: pointer;
  font-size: 20px;
  max-width: 1033px;
  background-color: transparent;
  margin: 0 auto;
  /* height: 90px; */
  align-items: center;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 0 30px;
  position: relative;

  .accordion-icon {
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1600px) {
    max-width: 100%;
    width: 100%;
  }

  @media screen and (min-width: 2000px) {
    max-width: 100%;
  }

  @media screen and (min-width: 2200px) {
    max-width: 100%;
    padding: 30px;

    .accordion-icon {
      img {
        width: 60px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 10px 30px;
  }
`;

export const accordionBundleItem = css`
  cursor: pointer;
  font-size: 20px;
  max-width: 1033px;
  background-color: transparent;
  margin: 0 auto;
  height: 48px;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 0;
  padding: 0 0px;
  position: relative;

  .accordion-icon {
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 2200px) {
    img {
      width: 35px !important;
    }
  }
`;

export const accordionTitle = css`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.01em;
  color: #181818;

  @media screen and (min-width: 1600px) {
    font-size: 24px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 24px;
  }
`;

export const accordionText = css`
  font-size: 16px;
  font-family: 'Manrope';
  font-weight: 400;

  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }

  @media screen and (min-width: 2000px) {
    font-size: 20px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 28px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const accordionItemButton = css`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;

  p {
    font-weight: 800;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    img {
      height: 36px;
      width: 36px;
    }

    p {
      font-size: 16px;
      font-weight: 800;
    }
  }
  @media screen and (min-width: 2000px) {
    font-size: 24px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 44px;

    p {
      font-weight: ${COUNTRY === 'mk' ? 600 : null};
    }
  }
`;

export const accordionBundleItemButton = css`
  height: 64px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  outline: none;
  background-color: white;
  z-index: 9999;

  @media screen and (min-width: 2200px) {
    .accordion-icon {
      img {
        width: 40px;
      }
    }
    font-size: 32px;
    height: 90px;
  }
`;
