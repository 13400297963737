import styled from '@emotion/styled';

export const FaqSectionWrapper = styled.div`
  .form-faq-send-question {
    width: 700px;
  }

  @media screen and (max-width: 370px) {
    .faq-section {
      h2 {
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .faq-section {
      padding: 60px 50px !important;
    }
  }

  @media screen and (max-width: 768px) {
    .form-faq-send-question {
      max-width: 100%;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 40px;
    }
    h4 {
      font-size: 12px;
    }
    .how-it-works-section {
      padding: 0px;
    }
    .phone {
      width: 206px;
    }
    .padding-20 {
      padding: 60px 20px;
    }
    .faq-section {
      padding: 40px 20px;
    }
  }

  @media screen and (min-width: 650px) and (max-width: 769px) {
    .faq-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .faq-section {
      padding: 60px 200px;
    }
  }

  @media screen and (min-width: 1400px) {
    .faq-section {
      padding: 60px 100px;
    }
    .faq-and-write-to-us-wrapper {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1500px) {
    .faq-section {
      padding: 60px 150px;
    }
    .faq-and-write-to-us-wrapper {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1600px) {
    .faq-section {
      padding: 60px 250px;
    }
    .faq-and-write-to-us-wrapper {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 2000px) {
    .faq-section {
      padding: 120px 400px;
    }
    .faq-and-write-to-us-wrapper {
      max-width: 1300px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 2200px) {
    .faq-and-write-to-us-wrapper {
      max-width: 1600px;
      margin: 0 auto;
    }
  }
`;
