import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  FeaturedPostImage,
  FeaturedPostLink,
  FeaturedPostText,
  FeaturedPostTitle,
  PostDescription,
  PostImage,
  PostTextWrapper,
  PostTitle,
  Press,
  PressSectionWrapper,
} from './press.styles';
import {
  AboveTitle,
  BadgeGray,
  BadgeLightGray,
  BadgeOrange,
  FlexCol,
  FlexWrapper,
  FlexWrapperStart,
  ImgWrapper,
  Title,
  VozziButton,
} from '../../../global/new-global-components';
import { navigateTo } from '../../../components/functional/Navigate';

export const PressSection = () => {
  const { t } = useTranslation();

  return (
    <PressSectionWrapper>
      <Press className="section press-section">
        <AboveTitle textTransform="normal">{t('home_VozziInTheMediaSubHeading')}</AboveTitle>
        <Title marginBottom="80px">
          <Trans i18nKey={'home_VozziInTheMediaHeading'}>
            part 1<span className="break-on-md-and-lg">part 2</span>
          </Trans>
        </Title>

        <FlexWrapper className="mobile-flex-reverse featured-post" overflow="hidden" backgroundColor="#1e1e23" borderRadius="20px">
          <FlexCol className="flex-col flex-col-text featured-post-text-inner" width="45%" padding="50px 60px">
            <a href="https://www.youtube.com/watch?v=EhvLJKepH-g" target="_blank" rel="noreferrer">
              <FlexWrapperStart className="mobile-stay-flex">
                <BadgeOrange>{t('home_VozziInTheMediaBadgeOneOrange2')}</BadgeOrange>
                <BadgeGray>{t('home_VozziInTheMediaBadgeOneGrey')}</BadgeGray>
              </FlexWrapperStart>
              <FeaturedPostTitle>
                <Trans i18nKey={'home_VozziInTheMediaPostOneHeading'}>
                  part 1<span className="break-on-md-and-lg">part 2</span>
                </Trans>
              </FeaturedPostTitle>
              <FeaturedPostText>{t('home_VozziInTheMediaPostOneText')}</FeaturedPostText>
              <FeaturedPostLink>{t('home_VozziInTheMediaPostOneSeeVideo')}</FeaturedPostLink>
            </a>
          </FlexCol>
          <FlexCol className="flex-col" width="55%">
            <a href="https://www.youtube.com/watch?v=EhvLJKepH-g" target="_blank" rel="noreferrer">
              <FeaturedPostImage src="/new-images/featured-post.png" />
            </a>
          </FlexCol>
        </FlexWrapper>

        <FlexWrapper className="posts-wrapper" margin="20px 0px">
          <FlexCol className="single-post" borderRadius="20px" backgroundColor="#f4f4f4" margin="40px 0px" width="48%">
            <a href="https://www.youtube.com/watch?v=vFyfbg8Lr9c" target="_blank" rel="noreferrer">
              <FlexWrapperStart className="mobile-stay-flex">
                <ImgWrapper className="single-post-image-wrapper" overflow="hidden" position="relative" maxWidth="170px">
                  <PostImage src="/new-images/video-post.png" />
                </ImgWrapper>
                <PostTextWrapper>
                  <FlexWrapperStart className="mobile-stay-flex">
                    <BadgeOrange>{t('home_VozziInTheMediaBadgeTwoOrange')}</BadgeOrange>
                    <BadgeLightGray>{t('home_VozziInTheMediaBadgeTwoGrey')}</BadgeLightGray>
                  </FlexWrapperStart>
                  <PostTitle>{t('home_VozziInTheMediaPostTwoHeading')}</PostTitle>
                  <PostDescription>{t('home_VozziInTheMediaPostTwoText')}</PostDescription>
                </PostTextWrapper>
              </FlexWrapperStart>
            </a>
          </FlexCol>
          <FlexCol className="single-post" borderRadius="20px" backgroundColor="#f4f4f4" margin="40px 0px" width="48%">
            <a href="https://www.youtube.com/watch?v=_DgZoGtYtzI" target="_blank" rel="noreferrer">
              <FlexWrapperStart className="mobile-stay-flex">
                <ImgWrapper className="single-post-image-wrapper" overflow="hidden" position="relative" maxWidth="170px">
                  <PostImage src="/new-images/regular-post.png" />
                </ImgWrapper>
                <PostTextWrapper>
                  <FlexWrapperStart className="mobile-stay-flex">
                    <BadgeOrange>{t('home_VozziInTheMediaBadgeThreeOrange')}</BadgeOrange>
                    <BadgeLightGray>{t('home_VozziInTheMediaBadgeThreeGrey')}</BadgeLightGray>
                  </FlexWrapperStart>
                  <PostTitle>{t('home_VozziInTheMediaPostThreeHeading')}</PostTitle>
                </PostTextWrapper>
              </FlexWrapperStart>
            </a>
          </FlexCol>
        </FlexWrapper>

        <VozziButton
          onClick={() => {
            navigateTo('/sr/novosti');
          }}
          style={{ margin: '0 auto' }}
        >
          {t('home_VozziInTheMediaCTAMoreNews')}
        </VozziButton>
      </Press>
    </PressSectionWrapper>
  );
};
