import styled from '@emotion/styled';

export const WhyVozziSectionWrapper = styled.div`
  .vertical-slider .slick-slide {
    padding: 30px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .vertical-ruler-btn {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 140px;
    right: 400px;
    overflow: hidden;
    background-color: white;
    border-radius: 50%;
    flex-direction: column;
    z-index: 9999;
    box-shadow: 0px 10px 50px -5px rgba(5, 3, 32, 0.2);
    display: none;
  }

  .vertical-ruler-btn button {
    display: block;
    width: 100%;
    height: 35%;
    background-color: transparent;
    border: none;
  }

  .ball {
    display: none;
    height: 180px;
    width: 180px;
    position: fixed;
    top: 0;
    opacity: 1;
    pointer-events: none;
    left: 0;
    z-index: 999999;
  }

  .vertical-slider-wrapper {
    padding: 30px 0;
    height: auto;
  }

  .vertical-slider-holder {
    cursor: none;
  }

  .vertical-slider .slick-track {
    height: auto !important;
  }

  .vertical-slider-col {
    min-width: 324px;
  }

  @media screen and (max-width: 768px) {
    .ball {
      display: none;
    }

    .why-vozzi-section {
      padding: 40px 20px;

      h2 {
        font-size: 28px;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 12px;
      }
    }

    .vertical-slider-wrapper p {
      font-size: 12px;
      width: 132px;
    }

    .vertical-slider .slick-track {
      height: auto !important;
    }

    .vertical-slider .slick-list {
      overflow: scroll !important;
      width: 100%;
    }

    .vertical-slider .slick-slide {
      width: 100%;
    }

    .vertical-slider .flex-col-50 {
      width: 50%;
    }

    .vertical-slider-wrapper .mobile-stay-flex > .mobile-stay-flex {
      justify-content: flex-start;
    }

    .vertical-slider-col {
      min-width: auto;
    }
  }

  @media screen and (max-width: 370px) {
    .why-vozzi-section h2 {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .why-vozzi-section {
      padding: 60px 50px;
    }
  }

  @media screen and (max-width: 1024px) {
    .vertical-slider-flex-wrapper {
      flex-wrap: wrap;
    }

    .vertical-slider-holder {
      width: 100%;
      flex: 0 0 100%;
    }

    .vertical-slider .slick-list {
      height: auto !important;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    .vertical-slider-col {
      font-size: 14px;
      min-width: 250px;
      width: 250px;
    }
  }

  @media screen and (min-width: 2200px) {
    .why-vozzi-section {
      > div:first-child {
        max-width: initial;
      }
    }

    .vertical-slider-wrapper {
      img {
        width: 35px;
      }
    }

    .vertical-slider-head-titles {
      p {
        font-size: 40px;
      }

      img {
        width: 240px;
      }
    }

    .ball {
      width: 320px;
      height: 320px;
    }

    .vertical-slider-col {
      min-width: 450px;
      max-width: 450px !important;
      font-size: 22px;
    }
  }
`;

export const WhyVozzi = styled.div`
  height: auto;
  position: relative;
  background-color: #ffffff;
  height: auto;

  padding: 120px 300px;

  @media screen and (min-width: 769px) {
    padding: 80px 50px;
  }

  @media screen and (min-width: 1400px) {
    padding: 120px 100px;
  }

  @media screen and (min-width: 1500px) {
    padding: 120px 150px;
  }

  @media screen and (min-width: 1600px) {
    padding: 120px 200px;
  }

  @media screen and (min-width: 2000px) {
    padding: 120px 200px;
  }

  /* @media screen and (max-width: 1792px) {
    padding: 120px 250px;
    padding-bottom: 70px;
  } */
`;
