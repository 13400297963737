import styled from '@emotion/styled';

export const ShareImpressionSectionWrapper = styled.div`
  .share-impression-section {
    padding: 40px 30px;
  }
  .share-impression-title {
    font-size: 32px;
  }

  @media screen and (max-width: 370px) {
    .share-impression-title {
      font-size: 26px;
    }
  }

  @media screen and (max-width: 500px) {
    .share-impression-section {
      background-image: url('/new-images/share-impression-mobile.png');
    }
  }

  @media screen and (min-width: 501px) {
    .share-impression-section {
      background-image: url('/new-images/share-impression.png');
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 2000px) {
    h2 {
      max-width: 1300px;
    }
  }

  @media screen and (min-width: 2200px) {
    .share-impression-title {
      font-size: 74px;
    }

    .share-impression-section {
      padding: 40px 40px;
    }
  }
`;
