import React, { useState } from 'react';
import { AboveTitle, Img, Title, VozziButton, Wrapper } from '../../../global/new-global-components';
import { ShareImpressionSectionWrapper } from './share_impression.css';
// @ts-ignore
import Tilt from 'react-tilt';
import { TestimonialFormSection } from '../../../components/layout/SendTestimonialModal/testimonial-form/TestimonialForm.section';
import { useTranslation } from 'react-i18next';

const ShareImpression = () => {
  const { t } = useTranslation();
  const [showPopupForm, setShowPopupForm] = useState(false);

  return (
    <>
      <ShareImpressionSectionWrapper>
        <Tilt options={{ max: 2, scale: 1, easing: 'cubic-bezier(.03,.98,.52,.99)', perspective: 1000 }}>
          <Wrapper borderRadius="22px" padding="75px" className="share-impression-section section">
            <AboveTitle marginBottom="10px" color="white" textAlign="left">
              {t('home_TestimonialsCTAsubHeading')}
            </AboveTitle>
            <Title color="white" fontSize="32px" className="share-impression-title" fontWeight="800" textAlign="left" maxWidth="512px">
              {t('home_TestimonialsCTAtext')}
            </Title>

            <VozziButton onClick={() => setShowPopupForm(true)} className="mobile-left" marginTop="40px">
              <Img src="/icons/write.svg" /> {t('home_TestimonialsCTAButton')}
            </VozziButton>
          </Wrapper>
        </Tilt>
      </ShareImpressionSectionWrapper>
      <TestimonialFormSection show={showPopupForm} onHide={() => setShowPopupForm(false)} />
    </>
  );
};

export default ShareImpression;
