import styled from '@emotion/styled';
import { COUNTRY } from '../../../../configs/env';

export const CardWrapper = styled.div`
  width: 514px;
  height: 264px;
  padding: 50px;
  margin-top: 100px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #eeeeee;
  /* box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1); */
  box-shadow: 15px 15px 40px 22px rgb(0 0 0 / 10%);

  transition: all 0.45s ease;
  opacity: 0;
  position: absolute;
  transform: scale(0.85) translateY(100px);

  h2,
  p {
    line-height: 1.5;
    margin: 0;
  }

  &&.active {
    opacity: 1;
    transform: scale(0.9) translateY(70px);
    box-shadow: 0 30px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  &&.next {
    opacity: 1;
    z-index: 0;
  }

  &&.inactive {
    transform: scale(0.85) translateY(100px);
    background-color: white;
    opacity: 1;
    z-index: 0;
  }

  &&.prev {
    transform: scale(0.8) translateY(130px);
    background-color: white;
    opacity: 1;
    z-index: -1;
  }

  @media screen and (max-width: 370px) {
    width: 250px;
    padding: 15px;

    p {
      font-size: 12px !important;
    }

    .card-title {
      font-size: 18px !important;
    }
  }

  @media screen and (max-width: 768px) {
    width: 270px;
    margin: 0 auto;
    padding: 20px;
    height: auto;
    left: 50%;
    margin-left: -135px;
    transform: scale(0.85) translateY(25px) !important;
    margin-top: 0px;

    &&.active {
      transform: scale(0.9) translateY(36px) !important;
    }

    .card-title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    width: auto;
    height: auto;
    min-height: 200px;
    padding: 40px 30px;
    margin-top: 0;

    &&.next {
      opacity: 1;
      z-index: 0;
    }

    &&.inactive {
      transform: scale(0.85) translateY(70px);
    }

    &&.prev {
      transform: scale(0.8) translateY(75px);
    }

    .card-title {
      font-size: 22px;
    }
  }

  @media screen and (min-width: 1600px) {
    width: 600px;
    height: ${COUNTRY === 'mk' ? '260px !important' : null};
    .card-title {
      font-size: 32px;
    }
    p {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 2000px) {
    .card-title {
      font-size: 36px !important;
    }
    p {
      font-size: 22px !important;
    }
  }

  @media screen and (min-width: 2200px) {
    height: ${COUNTRY === 'mk' ? '380px !important' : '380px'};
    width: ${COUNTRY === 'mk' ? '900px' : '700px'};
    .card-title {
      font-size: 48px !important;
    }
    p {
      font-size: 32px !important;
    }
  }
`;
