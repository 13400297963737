import Popup from '../../../components/new_popup_component/Popup';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AboveTitle, FlexWrapper, Img, Text, Title, VozziButton, Wrapper } from '../../../global/new-global-components';
import { BenefitsCardsWrapper, BenefitsSectionWrapper } from './benefits.styles';

export const BenefitsSection = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  return (
    <BenefitsSectionWrapper>
      <Wrapper
        padding="200px 150px"
        position="relative"
        backgroundSize="cover"
        backgroundImage="url('/new-images/footer-top-section-background.png')"
        className="benefits-section section"
        id="benefits-section"
      >
        <AboveTitle textTransform="normal" textAlign="left" color="white">
          {t('home_BenefitsSubHeading')}
        </AboveTitle>
        <FlexWrapper className="flex-wrapper mobile-margin-bottom-20" marginBottom="100px">
          <Title textAlign="left" color="white">
            {t('home_BenefitsHeading')}
          </Title>
        </FlexWrapper>
        <FlexWrapper className="benefits-flex-wrapper" alignItems="flex-end">
          <BenefitsCardsWrapper className="flex-wrapper benefits-cards padding-20">
            <Wrapper className="benefits-card" maxWidth="320px" width="320px" padding="30px 60px">
              <Wrapper>
                <Img marginBottom="30px" src="/icons/no-papers.png" />
                <Title as="h6" textAlign="left" marginBottom="15px" fontSize="24px">
                  {t('home_BenefitsTitleThree')}
                </Title>
                <Text fontSize="16px">{t('home_BenefitsSubTextThree')}</Text>
              </Wrapper>
            </Wrapper>
            <Wrapper className="benefits-card" maxWidth="320px" width="320px" padding="30px 60px">
              <Wrapper>
                <Img marginBottom="30px" src="/icons/clock.png" />
                <Title as="h6" textAlign="left" marginBottom="15px" fontSize="24px">
                  {t('home_BenefitsTitleTwo')}
                </Title>
                <Text fontSize="16px">{t('home_BenefitsSubTextTwo')}</Text>
              </Wrapper>
            </Wrapper>
            <Wrapper className="benefits-card" maxWidth="320px" width="320px" padding="30px 60px">
              <Wrapper>
                <Img marginBottom="30px" src="/icons/phone.png" />
                <Title as="h6" textAlign="left" marginBottom="15px" fontSize="24px">
                  {t('home_BenefitsTitleOne')}
                </Title>
                <Text fontSize="16px">{t('home_BenefitsSubTextOne')}</Text>
              </Wrapper>
            </Wrapper>
          </BenefitsCardsWrapper>
          <VozziButton onClick={() => setShowPopup(true)} className="benefits-button mobile-left">
            <Img src="/icons/receive-square.svg" /> {t('general_GetAppButtonTitle')}
          </VozziButton>
          <Popup
            setShowModal={setShowPopup}
            showModal={showPopup}
            aboveTitle={t('home_CTAsectionSubHeading')}
            title={t('home_CTAsectionHeading')}
            elementPosition={2}
          />
        </FlexWrapper>
      </Wrapper>
    </BenefitsSectionWrapper>
  );
};
