import React from 'react';
import { AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { accordionItem, accordionItemButton, accordionText, accordionTitle } from '../../../../global/global.css';

type AccordionCustomItemProps = {
  id: any;
  faq: any;
};

export const AccordionCustomItem: React.FC<AccordionCustomItemProps> = ({ ...props }) => {
  const [iconFirst, setIconFirst] = React.useState('/icons/accordion-expanded.svg');
  const [icon, setIcon] = React.useState('/icons/accordion-collapsed.svg');
  const [expandedFirst, setExpandedFirst] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      {props.id === 0 ? (
        <AccordionItem
          dangerouslySetExpanded={expandedFirst}
          onClick={() => {
            expandedFirst ? setExpandedFirst(false) : setExpandedFirst(true);
            iconFirst === '/icons/accordion-collapsed.svg'
              ? setIconFirst('/icons/accordion-expanded.svg')
              : setIconFirst('/icons/accordion-collapsed.svg');
          }}
          key={props.faq.title}
          css={accordionItem}
          style={{ backgroundColor: 'white', border: 'none' }}
        >
          <AccordionItemHeading css={accordionTitle}>
            <AccordionItemButton css={accordionItemButton}>
              <p>{props.faq.title}</p>
              <span className="accordion-icon" aria-hidden="true">
                <img src={iconFirst} />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p css={accordionText}>{props.faq.text}</p>
            {props.faq.img ? <img style={{ marginTop: 15 }} src={props.faq.img} /> : null}
          </AccordionItemPanel>
        </AccordionItem>
      ) : (
        <AccordionItem
          dangerouslySetExpanded={expanded}
          onClick={() => {
            expanded ? setExpanded(false) : setExpanded(true);
            icon === '/icons/accordion-collapsed.svg'
              ? setIcon('/icons/accordion-expanded.svg')
              : setIcon('/icons/accordion-collapsed.svg');
          }}
          key={props.faq.title}
          css={accordionItem}
          style={{
            backgroundColor: expanded ? 'white' : 'transparent',
            border: expanded ? 'none' : '1px solid #d8d8da',
          }}
        >
          <AccordionItemHeading css={accordionTitle}>
            <AccordionItemButton css={accordionItemButton}>
              <p>{props.faq.title}</p>
              <span className="accordion-icon" aria-hidden="true">
                <img src={icon} />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p css={accordionText}>{props.faq.text}</p>
            {props.faq.img ? <img style={{ marginTop: 15 }} src={props.faq.img} /> : null}
          </AccordionItemPanel>
        </AccordionItem>
      )}
    </>
  );
};
