import React from 'react';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { Box, Flex, Image, Text } from 'rebass';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { Modal } from '../../Modal/Modal.component';

import { CustomErrorMessage } from '../../../functional/CustomErrorMessage';
import Dialog from '../../../functional/SweetAlert';
import { VozziPrimaryButton } from '../../_/button.component';
import { theme } from '../../../../layouts/main.layout';
import { Textarea } from '@rebass/forms';
import { buttonStyle, headingStyle, inputStyle, inputStyleExperience, labelTextStyle } from './TestimonialForm.css';
import { Wrapper } from '../../../../global/new-global-components';
import './styles.css';

interface TestimonialFormProps {
  testimonial: string;
  name: string;
  email: string;
}

const initialValues = {
  testimonial: '',
  name: '',
  email: '',
};

export const TestimonialFormSection = ({ show, onHide }: any) => {
  const { t } = useTranslation();
  const handleSubmit = async (data: TestimonialFormProps) => {
    try {
      const response = await axios.post('/customer-testimonial', data);

      if (response) {
        Dialog.fire({
          title: t('response_TestimonialSuccess'),
          icon: 'success',
          confirmButtonColor: theme.colors.vozziVividOrange,
        });
        onHide();
      }
    } catch (err) {
      Sentry.captureException(err);
      Dialog.fire({
        title: t('response_Error'),
        icon: 'error',
        confirmButtonColor: theme.colors.vozziVividOrange,
      });
    }
  };

  const validationSchema = object().shape({
    testimonial: string().required(t('validation_TestimonialFormText')),
    name: string().required(t('validation_NameAndSurname')),
    email: string().email(t('validation_ValidEmail')).required(t('validation_Email')),
  });

  return (
    <Wrapper>
      <Modal show={show} onHide={onHide}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ isValid }) => (
            <Form style={{ backgroundColor: 'white', borderRadius: 22, padding: 20 }}>
              <Flex mt={40} mx="auto" flexDirection="column" width={{ _: '80vw', xl: '700px' }} justifyContent="center">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text css={headingStyle}>{t('home_TestimonialsFormHeading')}</Text>

                  <Box onClick={onHide} style={{ cursor: 'pointer' }}>
                    <Image style={{ height: 20 }} src="/new-images/close.png" />
                  </Box>
                </Flex>

                <Field
                  as={Textarea}
                  css={inputStyleExperience}
                  id="testimonial"
                  name="testimonial"
                  placeholder={t('home_placeholder_TestimonialFormText')}
                  pt={4}
                />
                <CustomErrorMessage name="testimonial" />

                <Field css={inputStyle} id="name" name="name" placeholder={t('placeholder_NameAndSurname')} />
                <CustomErrorMessage name="name" />

                <Field css={inputStyle} name="email" placeholder={t('placeholder_Email')} />
                <CustomErrorMessage name="email" />

                <VozziPrimaryButton css={buttonStyle} my="10px" type="submit" disabled={!isValid}>
                  {t('btn_Send')}
                </VozziPrimaryButton>

                <Text css={labelTextStyle} mb={4}>
                  {t('home_TestimonialDisclaimer')}
                  <Link to={t('link_userDataPrivacyPolicy')} style={{ fontWeight: 'bold' }}>
                    {t('footer_LinkTwo')}
                  </Link>
                </Text>
              </Flex>
            </Form>
          )}
        </Formik>
      </Modal>
    </Wrapper>
  );
};
