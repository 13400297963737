import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { WhyVozzi, WhyVozziSectionWrapper } from './why-vozzi.styles';
import {
  AboveTitle,
  FlexCol,
  FlexWrapper,
  FlexWrapperNoAlign,
  Img,
  Text,
  Title,
  VozziButton,
  Wrapper,
} from '../../../global/new-global-components';
import WhyVozziVerticalSlider from './why-vozzi-vertical-slider';
import { navigateTo } from '../../../components/functional/Navigate';

export const WhyVozziSection = () => {
  const { t } = useTranslation();

  return (
    <WhyVozziSectionWrapper>
      <WhyVozzi className="why-vozzi-section section">
        <Wrapper maxWidth="800px">
          <AboveTitle textTransform="normal" textAlign="left">
            {t('home_whyVozziSubHeading')}
          </AboveTitle>
          <Title textAlign="left">
            <Trans i18nKey={'home_whyVozziHeading'}>
              part 1<span className="break-on-md-and-lg">part 2</span>
            </Trans>
          </Title>
        </Wrapper>
        <FlexWrapperNoAlign className="vertical-slider-flex-wrapper flex-wrapper-mobile">
          <FlexCol width="40%">
            <VozziButton onClick={() => navigateTo(t('link_webShop'))} className="mobile-left" marginTop="39px">
              <Img src="/vectors/arrow-right-white.svg" />
              {t('home_whyVozziHeadingCTAbtnText')}
            </VozziButton>
          </FlexCol>
          <FlexCol width="60%" className="vertical-slider-holder">
            <FlexWrapper justifyContent="space-around" className="vertical-slider-head-titles mobile-stay-flex" height="140px">
              <Text fontSize="24px" color="#1E1E23" opacity="0.7">
                {t('home_whyVozziBefore')}
              </Text>
              <Img width="140px" src="/vectors/vozzi-new-logo-svg.svg" />
            </FlexWrapper>
            <Wrapper className="scroll-slider" overflow="scroll" height="600px">
              <WhyVozziVerticalSlider />
            </Wrapper>
          </FlexCol>
        </FlexWrapperNoAlign>
      </WhyVozzi>
    </WhyVozziSectionWrapper>
  );
};
