import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AboveTitle, Title, Wrapper } from '../../../global/new-global-components';
import RoadWithCarComponent from './roadWithCar.component';
import { HowItWorksFirstSection, HowItWorksWrapper } from './howItWorks.styles';
import { ResponsiveContainer } from '../../../components/functional/ResponsiveContainer.functional';
import RoadWithCarMobileComponent from './roadWithCarMobile.component';

export const HowItWorksSection = () => {
  const { t } = useTranslation();

  return (
    <HowItWorksWrapper>
      <Wrapper
        id="how-it-works"
        backgroundColor="#e9e9eb"
        padding="0px 220px"
        position="relative"
        overflow="hidden"
        maxHeight="700px"
        className="how-it-works-section section"
      >
        <Wrapper position="relative">
          <HowItWorksFirstSection className="overlay-wrapper padding-20">
            <AboveTitle textAlign="left">{t('home_HowToSectionSubHeading')}</AboveTitle>
            <Title className="how-it-works-section-title" textAlign="left">
              <Trans i18nKey={'home_HowToSectionHeading'}>
                part 1<span className="break-on-md-and-lg">part2</span>
              </Trans>
            </Title>
          </HowItWorksFirstSection>
          <ResponsiveContainer breakpoint="sm">
            {(matches: any): JSX.Element => (
              <>
                {matches.mobile && <RoadWithCarMobileComponent />}
                {matches.desktop && <RoadWithCarComponent />}
              </>
            )}
          </ResponsiveContainer>
        </Wrapper>
      </Wrapper>
    </HowItWorksWrapper>
  );
};
