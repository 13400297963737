import styled from '@emotion/styled';

export const BlogSectionWrapper = styled.div`
  .first-blog-slider .slick-slide {
    overflow: hidden !important;
    border-radius: 22px;
    max-width: 390px;
    margin-right: 60px;
    background-color: white;
    box-shadow: 2px 1px 2px rgb(0 0 0 / 6%);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .first-blog-slider .slick-slide img {
    width: 100%;
  }

  .first-blog-slider .slick-list {
    overflow: hidden;
  }

  .first-blog-slider .slick-track {
    padding-left: 15px;
    display: flex;
    justify-content: flex-start;
  }

  .first-blog-slider .slick-slide-title-wrapper {
    padding: 20px 35px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    height: 180px;
  }

  .first-blog-slider .blog-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #1e1e23;
  }

  .first-blog-slider .slick-slide img {
    max-width: 100%;
  }

  .second-blog-slider {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }

  .blog-second-slider-wrapper {
    position: relative;
    padding: 60px 300px;
    padding-bottom: 140px;
  }

  .second-blog-slider .slick-slide > div {
    margin-right: 6px;
    margin-left: 0;
  }

  .second-blog-slider .slick-list {
    margin-left: 20px;
    width: 300px;
    overflow: hidden;
  }

  .second-blog-slider .slick-track {
    display: flex;
  }

  .second-blog-slider .slick-current * {
    color: #121212 !important;
    opacity: 1 !important;
  }

  .second-blog-slider .slick-slide * {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: #121212;
    opacity: 0.5;
  }

  .blog-second-slider-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .first-blog-slider .slick-slide {
    transition: ease 1s;
  }

  .first-blog-slider .slick-current + .slick-slide + .slick-slide + .slick-slide {
    opacity: 0.5;
    filter: grayscale(20);
  }

  .blogArrows {
    justify-content: flex-end;

    .slick-list {
      display: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    .blog-slider-wrapper {
      padding: 0 !important;
    }

    .first-blog-slider .slick-slide {
      max-width: 255px !important;
      margin-right: 30px !important;
    }

    .first-blog-slider .slick-slide .blog-title {
      font-size: 18px;
    }

    .blog-second-slider-wrapper {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }

    .second-blog-slider {
      display: flex !important;
      align-items: center !important;
    }

    .second-blog-slider .slick-slide {
      margin: 0 !important;
    }

    .second-blog-slider .slick-list {
      max-width: 180px;
    }

    .second-blog-slider .slick-track {
    }

    .first-blog-slider .slick-slide-title-wrapper {
      padding: 15px;
      height: 140px;
    }
  }

  @media screen and (min-width: 650px) and (max-width: 769px) {
    .blog-slider-wrapper {
      padding-left: 30px !important;
    }

    .first-blog-slider .slick-slide {
      width: 380px !important;
      max-width: 380px !important;
    }

    .second-blog-slider .slick-list {
      visibility: hidden;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    .first-blog-slider .slick-slide .blog-title {
      font-size: 20px;
    }

    .first-blog-slider .slick-slide .slick-slide-title-wrapper {
      padding: 20px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .blog-second-slider-wrapper {
      padding: 60px 50px;
    }

    .blog-slider-wrapper {
      padding: 0px 0px 0px 50px !important;
    }

    .blog-section-title-wrapper {
      padding: 60px 0;

      h2 {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .blog-slider-wrapper {
      padding-left: 100px !important;
    }

    .blog-second-slider-wrapper {
      padding: 60px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .blog-slider-wrapper {
      padding-left: 150px !important;
    }

    .blog-second-slider-wrapper {
      padding: 60px 150px;
    }
  }

  @media screen and (min-width: 1600px) {
    .blog-slider-wrapper {
      padding-left: 200px !important;
    }

    .blog-second-slider-wrapper {
      padding: 60px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .blog-slider-wrapper {
      padding-left: 200px !important;
    }

    .blog-slider-wrapper .slick-slide {
      max-width: 420px;
      width: 420px;
    }

    .first-blog-slider .slick-current + .slick-slide + .slick-slide + .slick-slide {
      opacity: 1;
      filter: none;
    }

    .first-blog-slider .slick-current + .slick-slide + .slick-slide + .slick-slide + .slick-slide {
      opacity: 0.5;
      filter: grayscale(20);
    }

    .blog-second-slider-wrapper {
      padding: 60px 200px;
    }
  }

  @media screen and (min-width: 2200px) {
    .blog-slider-wrapper .slick-slide {
      max-width: 580px;
      width: 580px;
      height: 520px;
      background-color: white;
    }
    .blog-slide-img-wrapper {
      max-height: 280px !important;
    }

    .first-blog-slider {
      .slick-slide {
        .blog-title {
          font-size: 36px;
        }
      }
    }

    .first-blog-slider .slick-current + .slick-slide + .slick-slide + .slick-slide {
      opacity: 0.5;
      filter: grayscale(20);
    }

    .second-blog-slider {
      width: 460px !important;
      .slick-slide {
        div {
          div {
            font-size: 26px;
          }
        }
      }
      .slick-prev,
      .slick-next {
        height: 86px !important;
        width: 86px !important;
      }
    }
  }
`;

export const Blog = styled.div`
  height: auto;
  position: relative;
  background-color: #e9e9eb;
  background-size: 100% auto !important;
  height: auto;
  // padding: 280px 300px;
  .blog-slider-wrapper {
    padding: 0 0 300px 300px;
    padding-left: 300px;
    padding-bottom: 0;
  }
  /* .blog-title-wrapper {
    padding: 280px 300px;
    padding-bottom: 0;
  } */

  @media screen and (max-width: 1792px) {
    // padding: 280px 280px 300px 300px;
    // padding-bottom: 70px;
    .blog-slider-wrapper {
      padding: 0 0 220px 0;
      padding-left: 300px;
      padding-bottom: 0;
    }
    /* .blog-title-wrapper {
      padding: 220px 300px;
      padding-bottom: 0;
    } */
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0px 40px 20px;
    h2 {
      margin-bottom: 40px;
    }
    .blog-section-title-wrapper {
      flex-direction: row-reverse;
      padding-right: 20px !important;
    }
    .blog-second-slider-wrapper {
      flex-direction: column-reverse;
    }
    .second-blog-slider {
      margin-top: 20px;
      margin-left: auto;
      margin-right: 50px;
    }
  }
`;
