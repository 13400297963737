import styled from '@emotion/styled';

export type PropsType = {
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  margin?: string;
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  border?: string;
  fontSize?: string;
  opacity?: string;
  height?: string;
  width?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  flexWrap?: string;
  textAlign?: string;
  fontWeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  overflow?: string;
  objectFit?: string;
  flexDirection?: string;
  position?: string;
};

export const HeroSectionWrapper = styled.div`
  position: relative;

  .hand-mobile {
    position: absolute;
    right: 0;
    bottom: -300px;
    height: 120vh;
  }

  #counter1,
  #counter2 {
    min-width: 90px;
    display: inline-block;
  }

  .hero-numbers {
    h2 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 370px) {
    .hero-numbers {
      p {
        font-size: 10px !important;
      }
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .hero-section {
      flex-wrap: wrap !important;
      height: 100% !important;
    }

    .hero-section-left-col {
      padding: 60px 50px 80px 50px !important;
      width: 100vw !important;
      flex: 0 0 100vw !important;
    }

    .hero-section-right-col {
      width: 100vw !important;
      flex: 0 0 100vw !important;
    }

    #counter1,
    #counter2 {
      font-size: 50px;
    }

    .hero-numbers {
      p {
        font-size: 16px !important;
      }
    }

    #hand {
      max-height: 130vh !important;
    }

    #hand-mobile {
      top: 50vh !important;
    }

    #zatrazi-izlazak-servisera {
      height: 153px !important;
      width: 280px !important;
    }

    #serviser-uskoro-stize {
      width: 185px !important;
    }

    .hero-hand-mobile-flex-wrapper {
      top: 30vh !important;
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .hero-section-left-col {
      padding: 60px 50px 80px 50px !important;
      width: 55vw;
      flex: 0 0 55vw;
    }

    #hand {
      height: auto !important;
      max-height: 120vh;
      top: 170px;
    }

    .hero-hand-mobile-flex-wrapper {
      top: 60vh;
      right: 10px;
    }

    #zatrazi-izlazak-servisera {
      height: 154px;
      width: 274px;
    }

    #serviser-uskoro-stize {
      width: 173px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1099px) {
    #hand {
      max-height: 80vh;
    }

    #hand-mobile {
      top: -30px;
    }

    .hero-section-left-col {
      width: 55vw;
      flex: 0 0 55vw;
    }

    .hero-section-right-col {
      width: 45vw;
      flex: 0 0 45vw;
    }

    #zatrazi-izlazak-servisera {
      height: 113px;
      width: 200px;
    }

    #serviser-uskoro-stize {
      width: 125px;
    }

    .hero-hand-mobile-flex-wrapper {
      top: 30vh;
    }

    .hero-section {
      .hero-numbers {
        p {
          font-size: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    #hand {
      max-height: 90vh;
    }

    #hand-mobile {
      top: -30px;
    }

    .hero-section-left-col {
      width: 55vw;
      flex: 0 0 55vw;
    }

    .hero-section-right-col {
      width: 45vw;
      flex: 0 0 45vw;
    }

    .hero-section {
      .hero-numbers {
        p {
          font-size: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1399px) {
    #zatrazi-izlazak-servisera {
      height: 153px;
      width: 280px;
    }

    #serviser-uskoro-stize {
      width: 185px;
    }

    .hero-hand-mobile-flex-wrapper {
      top: 30vh;
    }
  }

  @media screen and (max-width: 768px) {
    .badge {
      margin-top: 20px;
      font-size: 12px;
      margin-right: 5px;
    }

    .badge:last-child {
      margin-right: 0;
    }

    .badge img {
      display: none;
    }

    .flex-col {
      padding: 0px;
      display: block;
      width: 100%;
    }

    .flex-col-padding-20 {
      padding: 0 20px 20px 20px;
    }

    p {
      font-size: 14px;
    }

    h1 {
      text-align: left;
      font-size: 40px;
    }

    .hero-section {
      padding: 0px;
      height: auto;

      .hero-badge-flex-wrapper {
        justify-content: flex-start;
      }

      .hero-numbers {
        align-items: flex-start;

        h2 {
          margin-left: 0;
          margin-right: 0;
        }

        p {
          font-size: 12px;
          margin: 0 20px;
        }
      }
    }

    .mobile-hidden {
      display: none;
    }

    button {
      height: 50px;
      font-size: 16px;
      border-radius: 15px;
      margin-bottom: 40px;
    }

    .video-caption {
      display: flex;
      justify-content: space-between;
      height: 90px;

      & .play-btn {
        border-radius: 50%;
        margin-bottom: 0;
        height: 60px;
        width: 60px;
        padding: 0;
        align-items: center;
        justify-content: center;
        display: flex;

        & .play-btn-inner {
          height: 42px;
          width: 42px;

          & img {
            width: 10px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    .hero-section {
      height: 100%;

      .flex-col-padding-20 {
        padding: 0 50px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .hero-section {
      height: 100%;

      .flex-col-padding-20 {
        padding: 0 50px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .hero-section {
      .flex-col-padding-20 {
        padding: 0 100px;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .hero-section {
      .flex-col-padding-20 {
        padding: 0 150px;
      }
    }
  }

  @media screen and (min-width: 1601px) {
    .hero-section {
      .flex-col-padding-20 {
        padding: 0 200px;
      }

      p {
        font-size: 20px;
      }

      .hero-numbers {
        h2 {
          font-size: 32px;
        }

        p {
          max-width: 140px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .hero-section {
      .flex-col-padding-20 {
        padding: 0 200px;
      }
    }

    .hero-section p {
      font-size: 30px;
      max-width: 700px;
    }
  }

  @media screen and (min-width: 2400px) {
    .hero-section p {
      font-size: 38px;
      max-width: initial;
    }
    #hand-mobile {
      top: 100px;
      height: 120vh;
    }

    .hero-hand-mobile-flex-wrapper {
      right: 200px;
    }

    #hand {
      max-height: 120vh;
      height: 120vh;
    }

    #zatrazi-izlazak-servisera {
      height: 280px;
      width: 500px;
    }

    #serviser-uskoro-stize {
      width: 300px;
      height: auto;
    }

    .hero-section {
      .hero-section-left-col {
        width: 55%;
        flex: 0 0 55%;
      }

      .hero-section-right-col {
        width: 45%;
        flex: 0 0 45%;
      }

      .hero-numbers {
        h2 {
          font-size: 64px;
        }
        p {
          font-size: 22px;
          max-width: initial;
        }
      }
    }
  }
`;

export const VideoCaption = styled.div`
  height: 140px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: ${(props: PropsType) => props.position || 'static'};
  bottom: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 40px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const PlayButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 70px;
  width: 70px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  justify-content: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 50px;
    height: 50px;
  }
`;

export const PlayButtonInner = styled.div`
  width: 54px;
  height: 54px;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    height: 36px;
    width: 36px;
  }
`;
