import styled from '@emotion/styled';

export const PressSectionWrapper = styled.div`
  .single-post {
    overflow: hidden;
  }

  .featured-post {
    border-radius: 22px;
  }

  @media screen and (max-width: 768px) {
    .featured-post {
      margin-top: 40px;
      border-radius: 10px;

      .flex-col {
        width: 100%;
      }

      .flex-col-text {
        padding: 20px;
      }
    }

    .single-post {
      overflow: hidden;
      width: 100%;
      margin-top: 10px;
    }

    h2 {
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .press-section {
      padding: 60px 50px;
    }

    .featured-post {
      flex-wrap: wrap !important;
      flex-direction: column-reverse !important;

      .flex-col {
        width: 100%;
      }
    }

    .single-post {
      width: 100% !important;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }

    .posts-wrapper {
      flex-wrap: wrap;
    }

    .featured-post-text-inner {
      width: 100%;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1099px) {
    .featured-post-text-inner {
      padding: 25px;
    }

    .single-post {
      h2 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }

    .press-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .press-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1199px) {
    .featured-post-text-inner {
      padding: 50px;
    }

    .single-post {
      h2 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }

    .press-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 1199px) and (max-width: 1399px) {
    .single-post {
      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .press-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 2000px) {
    .press-section {
      padding: 100px 200px;
    }
  }
`;

export const Press = styled.div`
  height: auto;
  position: relative;
  background-color: #ffffff;
  height: auto;
  align-items: center;
  padding: 120px 300px;

  @media screen and (min-width: 1400px) {
    padding: 200px 100px;
  }

  @media screen and (min-width: 1500px) {
    padding: 200px 150px;
  }

  @media screen and (min-width: 1600px) {
    padding: 200px 200px;
  }

  @media screen and (min-width: 2000px) {
    padding: 200px 200px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
    .single-post-image-wrapper {
      width: 130px;
      height: 150px;
    }
  }
`;

export const FeaturedPost = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #1e1e23;
  border-radius: 20px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    border-radius: 15px;
  }
`;

export const FeaturedPostTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 125.5%;
  color: #ffffff;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    font-size: 22px;
  }

  @media screen and (min-width: 1199px) and (max-width: 1399px) {
    font-size: 28px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 62px;
  }
`;

export const FeaturedPostCol = styled.div`
  flex: 0 0 50%;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 15px;
  }
`;

export const FeaturedPostColWithPadding = styled(FeaturedPostCol)`
  padding: 80px 60px;
  @media screen and (max-width: 768px) {
    padding: 20px;
    width: 100%;
  }
`;

export const FeaturedPostText = styled.p`
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.7;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    font-size: 14px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 32px;
  }
`;

export const FeaturedPostLink = styled.div`
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: #ffffff;
  border-bottom: 1.5px solid #ffffff;
  display: inline-block;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    margin-top: 20px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 32px;
  }
`;

export const FeaturedPostImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;

export const Post = styled.div`
  flex: 0 0 48%;
  margin: 40px 0px;
  width: 48%;
  background-color: #f4f4f4;
  display: flex;
  justify-content: flex-start;
  border-radius: 20px;
  position: relative;

  @media screen and (max-width: 768px) {
    margin: 10px 0px 0px 0px;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    height: 150px;
    min-height: 150px;

    &&:last-child {
      margin-bottom: 40px;
    }
  }
`;

export const PostImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  max-width: 270px;
  @media screen and (max-width: 768px) {
    position: absolute;
    object-fit: cover;
    max-width: 130px;
    width: 130px;
    height: 150px;
  }
`;

export const PostTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  color: #1e1e23;
  margin-top: 5px;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 36px;
  }
`;

export const PostDescription = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #1e1e23;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 24px;
  }
`;

export const PostTextWrapper = styled.div`
  padding: 0px 25px;
  display: block;
  @media screen and (max-width: 768px) {
    padding: 20px 10px;
    width: calc(100% - 130px);
  }
`;
