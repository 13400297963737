import { Badge, FlexCol, FlexWrapperStart, Text, Title, Wrapper } from '../../../../global/new-global-components';
import React, { useEffect, useState } from 'react';
import { CardWrapper } from './card-component.styles';
import { useTranslation } from 'react-i18next';
import { COUNTRY } from '../../../../configs/env';

function determineClasses(indexes: any, cardIndex: any): string {
  if (indexes.currentIndex === cardIndex) {
    return 'active';
  } else if (indexes.nextIndex === cardIndex) {
    return 'next';
  } else if (indexes.previousIndex === cardIndex) {
    return 'prev';
  }
  return 'inactive';
}

const CardCarousel = (props: any): React.ReactElement => {
  const { t } = useTranslation();

  const [indexes, setIndexes] = useState({
    previousIndex: props.currentIndex - 1,
    currentIndex: props.currentIndex,
    nextIndex: props.currentIndex === 2 ? 0 : props.currentIndex + 1,
  } as any);

  useEffect(() => {
    setIndexes({
      previousIndex: props.currentIndex - 1,
      currentIndex: props.currentIndex,
      nextIndex: props.currentIndex === 2 ? 0 : props.currentIndex + 1,
    });
    return (): void =>
      setIndexes({
        previousIndex: null,
        currentIndex: null,
        nextIndex: null,
      });
  }, [props.currentIndex]);

  const cardItems = [
    {
      id: 1,
      step: '#1',
      title: t('home_HowToSectionFirstStepTitle'),
      description: t('home_HowToSectionFirstStepText'),
    },
    {
      id: 2,
      step: '#2',
      title: t('home_HowToSectionSecondStepTitle'),
      description: t('home_HowToSectionSecondStepText'),
    },
    {
      id: 3,
      step: '#3',
      title: t('home_HowToSectionThirdStepTitle'),
      description: t('home_HowToSectionThirdStepText'),
    },
    {
      id: 4,
      step: '#4',
      title: t('home_HowToSectionFourthStepTitle'),
      description: t('home_HowToSectionFourthStepText'),
    },
  ];

  return (
    <FlexCol className="flex-col" width="40%">
      <Wrapper className="cards-wrapper">
        {cardItems.map((card, index) => (
          <CardWrapper
            key={card.id}
            style={{ height: COUNTRY === 'mk' ? '330px' : '' }}
            className={`card-wrapper card ${determineClasses(indexes, index)}`}
          >
            <FlexWrapperStart className="mobile-stay-flex">
              {[1, 2, 3, 4].map((item: number) => (
                <Badge
                  key={item}
                  backgroundColor={item === index + 1 ? '#333333' : '#ADADAD'}
                  color="white !important"
                  padding="0px 15px"
                  alignItems="center"
                >
                  #{item}
                </Badge>
              ))}
            </FlexWrapperStart>
            <Title className="card-title" textAlign="left" fontSize="40px">
              {card.title}
            </Title>
            <Text>{card.description}</Text>
          </CardWrapper>
        ))}
      </Wrapper>
    </FlexCol>
  );
};

export default CardCarousel;
