import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import { gsap, MotionPathPlugin, ScrollTrigger } from 'gsap/all';
import { useTranslation } from 'react-i18next';
import { FlexCol, FlexWrapperStart, Img } from '../../../global/new-global-components';
import CardCarousel from './new_card_component/card-component';

const RoadWithCarComponent = (props: any) => {
  const { t, i18n } = useTranslation();
  const LANG = i18n.language;

  const firstImage = t('home_howItWorksFirstStepPhoneScreen');
  const [phoneImage, setPhoneImage] = useState(firstImage);
  const [cardCarouselIndex, setCardCarouselIndex] = useState(0);
  const carImage = useRef<any>(null);

  const secondImage = t('home_howItWorksSecondStepPhoneScreen');
  const thirdImage = t('home_howItWorksThirdStepPhoneScreen');
  const fourthImage = t('home_howItWorksFourthStepPhoneScreen');

  useEffect(() => {
    // const benefitsSection: HTMLElement|null = document.querySelector('#benefits-section');
    gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

    const tl = gsap.timeline();

    gsap.to('#rect', {
      motionPath: {
        path: '#path',
        align: '#path',
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      scrollTrigger: {
        trigger: '#how-it-works',
        start: window.innerWidth < 769 ? '-20% top' : '-80% -20%',
        end: window.innerWidth < 769 ? 'bottom' : 'bottom+=6000 bottom-=8000',
        scrub: 5,
        markers: false,
        onUpdate: (self: any) => {
          if (self.progress > 0.55) {
            if (carImage.current) {
              carImage.current.setAttribute('href', '/new-images/towing.png');
              carImage.current.style.height = '135px';
              carImage.current.style.width = '220px';
            }
          } else {
            if (carImage.current) {
              carImage.current.setAttribute('href', '/new-images/autic.png');
              carImage.current.style.height = '130px';
              carImage.current.style.width = '170px';
            }
          }
          if (self.progress < 0.15) {
            setPhoneImage(firstImage);
            setCardCarouselIndex(0);
          } else if (self.progress > 0.25 && self.progress < 0.35) {
            setPhoneImage(secondImage);
            setCardCarouselIndex(1);
          } else if (self.progress > 0.35 && self.progress < 0.55) {
            setPhoneImage(thirdImage);
            setCardCarouselIndex(2);
          } else if (self.progress > 0.55) {
            setPhoneImage(fourthImage);
            setCardCarouselIndex(3);
          }
        },
        onRefresh: (self: any) => {
          setTimeout(() => {
            if (self.progress < 0.15) {
              setPhoneImage(firstImage);
              setCardCarouselIndex(0);
            } else if (self.progress > 0.25 && self.progress < 0.35) {
              setPhoneImage(secondImage);
              setCardCarouselIndex(1);
            } else if (self.progress > 0.35 && self.progress < 0.55) {
              setPhoneImage(thirdImage);
              setCardCarouselIndex(2);
            } else if (self.progress > 0.55) {
              setPhoneImage(fourthImage);
              setCardCarouselIndex(3);
            }
          }, 500);
        },
      },
      immediateRender: true,
    });

    let scrollStart = '';
    if (window.innerWidth > 2000) {
      scrollStart = '-100 top';
    }

    if (window.innerWidth > 769) {
      scrollStart = '200 top';
    }

    if (window.innerWidth < 769) {
      scrollStart = '0 top';
    }

    ScrollTrigger.create({
      animation: tl,
      trigger: '#how-it-works',
      start: scrollStart /*window.innerWidth > 2000 ? '0 top' : '300 top',*/,
      end: '+=10000',
      pin: window && window.innerWidth > 768 ? true : false,
      anticipatePin: window && window.innerWidth > 768 ? 1 : 0,
    });

    // const isElementVisible = (el: any) => {
    //   let rect = el.getBoundingClientRect(),
    //     vWidth = window.innerWidth || document.documentElement.clientWidth,
    //     vHeight = window.innerHeight || document.documentElement.clientHeight,
    //     efp = (x: any, y: any) => {
    //       return document.elementFromPoint(x, y);
    //     };

    //   // Return false if it's not in the viewport
    //   if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) return false;

    //   // Return true if any of its four corners are visible
    //   return (
    //     el.contains(efp(rect.left, rect.top)) ||
    //     el.contains(efp(rect.right, rect.top)) ||
    //     el.contains(efp(rect.right, rect.bottom)) ||
    //     el.contains(efp(rect.left, rect.bottom))
    //   );
    // };

    // const debouncer = (func: any, timeout: any) => {
    //   console.log('DEBOUNCE');
    //   var timeoutID: any,
    //     timeout = timeout || 10000;
    //   return () => {
    //     var scope = this,
    //       args: any = [];
    //     clearTimeout(timeoutID);
    //     timeoutID = setTimeout(function () {
    //       func.apply(scope, Array.prototype.slice.call(args));
    //     }, timeout);
    //   };
    // };

    // window.addEventListener('scroll', (event) => {
    //   if (isElementVisible(document.getElementById('how-it-works'))) {
    //     window.addEventListener('wheel', (e) => debouncer);
    //   }
    // });

    // tl.to('#how-it-works', { duration: 2, translateY: 100 });
    return () => setCardCarouselIndex(0);
  }, []);

  return (
    <>
      <svg id="road-with-car" viewBox="0 0 1255 1729" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="path"
          d="M1203.5 50V342.402C1203.5 361.91 1195.36 380.532 1181.04 393.781L949.124 608.379C936.187 620.35 919.209 627 901.582 627H120.5C81.8401 627 50.5 658.34 50.5 697V916V1228.5C50.5 1267.16 81.8401 1298.5 120.5 1298.5H595.5H672.248C687.651 1298.5 702.625 1303.58 714.847 1312.95L919.599 1469.99C936.873 1483.23 947 1503.76 947 1525.53V1708.5"
          stroke="#1E1E23"
          strokeWidth="3"
          strokeLinecap="round"
          strokeDasharray="6 6"
        />
        <path
          d="M1205 50V342.402C1205 361.91 1196.86 380.532 1182.54 393.781L950.624 608.379C937.687 620.35 920.709 627 903.082 627H120.5C81.8401 627 50.5 658.34 50.5 697V916V1226.5C50.5 1265.16 81.8401 1296.5 120.5 1296.5H598.5H675.021C690.564 1296.5 705.664 1301.67 717.942 1311.2L919.922 1467.98C937.005 1481.24 947 1501.65 947 1523.28V1679"
          stroke="#3B3B3C"
          strokeOpacity="0.1"
          strokeWidth="100"
          strokeLinecap="round"
        />
        <g id="rect">
          <image
            id="car-img"
            ref={carImage}
            style={{ transform: 'rotate(180deg)', height: '130px', width: '140px', marginTop: '-60px' }}
            href="/new-images/autic.png"
          />
        </g>
      </svg>
      <FlexWrapperStart overflow="initial" className="how-it-works-flex-wrapper" padding="0px 0px 300px 0px">
        <FlexCol className="flex-col" textAlign="center" width="50%">
          <Img
            width="400px"
            position="relative"
            left="230px"
            marginBottom="-150px"
            data-tilt
            id="phone"
            className="phone"
            src={phoneImage}
          />
        </FlexCol>

        <CardCarousel currentIndex={cardCarouselIndex} />
      </FlexWrapperStart>
    </>
  );
};

export default RoadWithCarComponent;
