import Popup from '../../../components/new_popup_component/Popup';
import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { Trans, useTranslation } from 'react-i18next';
import {
  BadgeLightGray,
  BigTitle,
  FlexCol,
  FlexWrapper,
  Img,
  ImgWrapper,
  Span,
  Text,
  Title,
  VozziButton,
  Wrapper,
} from '../../../global/new-global-components';
import { HeroSectionWrapper } from './hero.styles';

export const HeroSection = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const video = useRef<any>(null);
  const imgWrapper = useRef<any>(null);
  const counter1 = useRef<any>(null);
  const counter2 = useRef<any>(null);
  const tl = gsap.timeline();

  useEffect(() => {
    const Count1 = { val: 0 },
      NewVal1 = 1500;
    const Count2 = { val: 0 },
      NewVal2 = 9570;

    gsap.to(Count1, {
      duration: 7,
      val: NewVal1,
      onUpdate: function () {
        if (counter1.current) {
          counter1.current.innerHTML = Count1.val.toFixed(0);
        }
      },
    });

    gsap.to(Count2, {
      duration: 7,
      val: NewVal2,
      onUpdate: function () {
        if (counter2.current) {
          counter2.current.innerHTML = Count2.val.toFixed(0);
        }
      },
    });
  }, []);

  return (
    <HeroSectionWrapper>
      <Wrapper id="hand-mobile" className="hand-mobile">
        <Wrapper className="hero-hand-mobile-inner">
          <Img position="relative" id="hand" src={t('home_heroSection_phoneScreen')} />
          <Wrapper className="hero-hand-mobile-flex-wrapper">
            <Img id="zatrazi-izlazak-servisera" src={t('home_heroSection_serviceCardOne')} />
            <Img id="serviser-uskoro-stize" src={t('home_heroSection_serviceCardTwo')} />
          </Wrapper>
        </Wrapper>
      </Wrapper>
      <FlexWrapper height="calc(100vh - 94px)" backgroundColor="#f4f4f4" className="flex-col hero-section section">
        <FlexCol
          className="flex-col flex-col-padding-20 flex-col-z-index hero-section-left-col"
          padding="0px 220px"
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexDirection="column"
          width="60vw"
        >
          <FlexWrapper className="hero-badge-flex-wrapper mobile-stay-flex">
            <BadgeLightGray className="badge">
              <Img marginRight="5px" width="20px" src="/icons/shield-tick-black.svg" />
              {t('home_HeroSectionChipsOne')}
            </BadgeLightGray>
            <BadgeLightGray className="badge">
              <Img marginRight="5px" width="20px" src="/icons/clock-black.svg" /> {t('home_HeroSectionChipsTwo')}
            </BadgeLightGray>
            <BadgeLightGray className="badge">
              <Img marginRight="5px" width="20px" src="/icons/card-remove-black.svg" /> {t('home_HeroSectionChipsThree')}
            </BadgeLightGray>
          </FlexWrapper>
          <BigTitle textAlign="left" margin="30px 0px">
            <Trans i18nKey={'home_HeroSectionHeading'}>
              <strong>part 0</strong>
              <span className="break-on-md-and-lg"></span>part 1
            </Trans>
          </BigTitle>
          <Text fontSize="24px" fontWeight="400" marginBottom="30px">
            <Trans i18nKey={'home_HeroSectionBottomSentence'}>
              part 1<span className="break-on-md-and-lg"></span>part 2
            </Trans>
          </Text>

          <FlexWrapper className="hero-numbers">
            <FlexWrapper className="mobile-stay-flex">
              <Title fontSize="32px" color="#FD9F3A">
                <Span ref={counter1} id="counter1">
                  0
                </Span>
                +
              </Title>
              <Text fontSize="14px" margin="20px 15px">
                <Trans i18nKey={'home_PartnersCounter'}>
                  part 1<span className="break-on-md-and-lg"></span>part 2
                </Trans>
              </Text>
            </FlexWrapper>
            <FlexWrapper className="mobile-stay-flex">
              <Title fontSize="14px" color="#FD9F3A" margin="20px 15px">
                <Span ref={counter2} id="counter2">
                  0
                </Span>
                +
              </Title>
              <Text fontSize="12px">
                <Trans i18nKey={'home_AssistanceCounter'}>
                  part 1<span className="break-on-md-and-lg"></span>part 2
                </Trans>
              </Text>
            </FlexWrapper>
          </FlexWrapper>

          <VozziButton onClick={() => setShowPopup(true)} className="mobile-left">
            <Img src="/icons/receive-square.svg" /> {t('home_HeroSectionCTAButton')}
          </VozziButton>
          <Popup
            setShowModal={setShowPopup}
            showModal={showPopup}
            aboveTitle={t('home_CTAsectionSubHeading')}
            title={t('home_CTAsectionHeading')}
          />
        </FlexCol>
        <FlexCol
          maxHeight="calc(100vh - 94px)"
          className="flex-col hero-section-right-col"
          // style={{ transition: 'ease .3s' }}
          ref={video}
          position="relative"
          width="40vw"
        >
          <ImgWrapper className="img-wrapper" ref={imgWrapper} position="relative">
            <Img objectFit="cover" width="100%" alt="img-new" src="/new-images/minimal-pattern.png" />
          </ImgWrapper>
        </FlexCol>
      </FlexWrapper>
    </HeroSectionWrapper>
  );
};
