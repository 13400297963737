import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { FlexWrapperNoAlign, Img, Text, Wrapper } from '../../../global/new-global-components';

import { gsap } from 'gsap/all';
import { useTranslation } from 'react-i18next';

const WhyVozziVerticalSlider = () => {
  const { t } = useTranslation();
  const ballRef = useRef<any>();
  const verticalSliderAreaRef = useRef<any>();

  const slider1 = useRef<any>();

  const posts = [
    {
      id: 1,
      left_section: t('home_whyVozziBeforeText1'),
      right_section: t('home_whyVozziAfterText1'),
    },
    {
      id: 2,
      left_section: t('home_whyVozziBeforeText2'),
      right_section: t('home_whyVozziAfterText2'),
    },
    {
      id: 3,
      left_section: t('home_whyVozziBeforeText3'),
      right_section: t('home_whyVozziAfterText3'),
    },
    {
      id: 4,
      left_section: t('home_whyVozziBeforeText4'),
      right_section: t('home_whyVozziAfterText4'),
    },
    {
      id: 5,
      left_section: t('home_whyVozziBeforeText5'),
      right_section: t('home_whyVozziAfterText5'),
    },
    {
      id: 6,
      left_section: t('home_whyVozziBeforeText6'),
      right_section: t('home_whyVozziAfterText6'),
    },
    {
      id: 7,
      left_section: t('home_whyVozziBeforeText7'),
      right_section: t('home_whyVozziAfterText7'),
    },
    {
      id: 8,
      left_section: t('home_whyVozziBeforeText8'),
      right_section: t('home_whyVozziAfterText8'),
    },
    {
      id: 9,
      left_section: t('home_whyVozziBeforeText9'),
      right_section: t('home_whyVozziAfterText9'),
    },

    {
      id: 10,
      left_section: t('home_whyVozziBeforeText10'),
      right_section: t('home_whyVozziAfterText10'),
    },
  ];

  const postComponents = posts.map((item: any, index) => {
    return (
      <Wrapper key={index} className="vertical-slider-wrapper">
        <FlexWrapperNoAlign className="mobile-stay-flex">
          <FlexWrapperNoAlign className="mobile-stay-flex flex-col-50">
            {item.left_section && <Img src="/vectors/slash.svg" style={{ marginRight: 15 }} />}

            <Text className="vertical-slider-col" style={{ maxWidth: '324px' }}>
              {item.left_section}
            </Text>
          </FlexWrapperNoAlign>
          <FlexWrapperNoAlign className="mobile-stay-flex flex-col-50">
            {item.right_section && <Img src="/vectors/tick-circle.svg" style={{ marginRight: 15 }} />}
            <Text className="vertical-slider-col" style={{ maxWidth: '324px' }}>
              {item.right_section}
            </Text>
          </FlexWrapperNoAlign>
        </FlexWrapperNoAlign>
      </Wrapper>
    );
  });

  const countPostComponents = postComponents.length;

  const arr = [];
  for (let i = 1; i < countPostComponents + 1; i++) {
    arr.push(i);
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    vertical: true,
    arrows: false,
    variableHeight: true,
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          infinite: false,
          centerMode: false,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    gsap.set(ballRef.current, { xPercent: -50, yPercent: -50 });

    verticalSliderAreaRef.current.addEventListener('mousemove', (e: any) => {
      ballRef.current.style.display = 'block';
      gsap.to(ballRef.current, 0.3, {
        x: e.clientX,
        y: e.clientY,
      });

      const TL = gsap.timeline({
        defaults: { duration: 0.3, ease: 'none' },
      });
      TL.to(ballRef.current, {
        scale: 1,
      });
    });

    verticalSliderAreaRef.current.addEventListener('pointerleave', (e: any) => {
      ballRef.current.style.display = 'none';
    });
  }, []);

  return (
    <Wrapper className="custom-wrapper">
      <img ref={ballRef} className="ball" src="/new-images/cursor.png" alt="cursor" />

      <Wrapper ref={verticalSliderAreaRef} id="vertical-slider-area">
        <Slider className="vertical-slider" ref={slider1} {...settings}>
          {postComponents}
        </Slider>
      </Wrapper>
    </Wrapper>
  );
};

export default WhyVozziVerticalSlider;
