import React, { ReactElement } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import GatsbyImg from 'gatsby-image';

import { theme } from '../../../../layouts/main.layout';

interface TestimonialProps {
  avatarImg?: any;
  name: string;
  title: string;
  description: string;
  content?: string;
  videoPoster?: string;
  mark?: number;
  date?: string;
  onClick?: any;
}

const testimonialBoxCss = css`
  overflow: hidden;
  border-radius: 6px;
  font-family: ${theme.fonts.text};
  background-color: transparent;
  margin-top: 0px;
  height: auto;
  border-radius: 20px;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 1px 10px 10px rgb(0 0 0 / 10%);

  > div {
    height: 490px;
  }

  // @media screen and (max-width: 400px) {
  //   height: 340px;
  // }
  // @media screen and (min-width: 400px) and (max-width: 1200px) {
  //   height: 400px;
  // }
  // @media screen and (min-width: 497px) and (max-width: 640px) {
  //   height: 480px;
  // }
  @media screen and (max-width: 768px) {
    height: 450px;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    height: 440px;
  }

  @media screen and (min-width: 2200px) {
    height: 760px;
    background-color: white;
  }
`;

const avatarImage = css`
  border-radius: 50%;
  height: 140px;
  width: 140px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 768px) {
    height: 70px;
    width: 70px;
  }
`;

const dateMarkContainer = css`
  position: relative;
  align-items: center;
  justify-content: space-between;

  div {
    font-size: 13px;
  }

  @media screen and (max-width: 768px) {
    div {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    padding: 0px 20px;
  }
`;

const posterImg = css`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    background: url('/img/play.svg') center no-repeat white;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    text-align: center;

    @media screen and (min-width: 2200px) {
      width: 120px;
      height: 120px;
      background: url('/img/play.svg') center no-repeat white;
      background-size: 26px;
    }
  }

  &:hover {
    &:before {
      box-shadow: 0 0 5px 0 white inset, 0 0 5px 2px white;
      border-radius: 50%;
    }
  }
`;

const testimonialTitle = css`
  font-weight: 600;
  font-size: 24px;
  padding: 15px 20px;
  text-align: left;
  padding-bottom: 0;
  @media screen and (max-width: 768px) {
    font-size: 16px !important;
    padding: 10px 10px;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    font-size: 20px;
  }

  @media screen and (min-width: 2000px) {
    text-align: left;
  }

  @media screen and (min-width: 2200px) {
    font-size: 36px;
    padding: 20px 40px;
  }
`;

const testimonialDescription = css`
  font-weight: 300 !important;
  font-size: 16px;
  text-transform: none !important;
  max-width: 324px;
  text-align: left !important;
  padding: 15px 20px;
  max-height: 185px;
  min-height: 185px;

  &&.avatar-only {
    max-height: 250px;
    min-height: 250px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 15px 15px;
    min-height: 225px;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    max-height: 145px;
    min-height: 145px;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    max-height: 200px;
    font-size: 15px;
    min-height: 200px;
  }

  @media screen and (min-width: 2000px) {
    max-width: 100%;
    width: 100%;
  }

  @media screen and (min-width: 2200px) {
    font-size: 26px;
    padding: 20px 40px;
    min-height: 400px;
  }
`;

const bottomContainer = css`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const markContainer = css`
  position: relative;
  right: 0;
  align-items: center;
  @media screen and (max-width: 768px) {
    img {
      height: auto;
    }
  }
`;

const TestimonialBox = ({
  avatarImg,
  name,
  title,
  description,
  content,
  date,
  mark,
  onClick,
  videoPoster,
}: TestimonialProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <Box css={testimonialBoxCss}>
      {/* {avatarImg && <GatsbyImg fluid={avatarImg} css={avatarImage} />} */}

      <Box
        css={css`
          width: 100%;
          background-color: white;
          border-radius: 20px;
        `}
      >
        <div style={{ paddingTop: avatarImg && !videoPoster ? 30 : 0 }}>
          {content && (
            <Text as="p" textAlign="center" fontWeight={theme.fontWeights.extraLight}>
              {t(content)}
            </Text>
          )}

          {videoPoster && (
            <Box
              css={posterImg}
              onClick={onClick}
              mx="auto"
              width={{ _: '100%', md: '100%' }}
              height={{ _: '100%', md: '100%' }}
              pt={{ _: '0', md: '0', sm: '0' }}
            >
              <GatsbyImg fluid={videoPoster} />
            </Box>
          )}

          {avatarImg && !videoPoster ? <GatsbyImg fluid={avatarImg} css={avatarImage} /> : null}

          {title && lang === 'sr' && <h3 css={testimonialTitle}>{title}</h3>}

          {description && (
            <p className={avatarImg && !videoPoster && 'avatar-only'} css={testimonialDescription}>
              {description}
            </p>
          )}

          <div css={bottomContainer}>
            {mark > 4.6 && (
              <Flex width="100%" p={3} pl={3} css={dateMarkContainer}>
                <div className="left-container" style={{ width: '50%' }}>
                  <Text className="testimonial-name" mr={1}>
                    {name}
                  </Text>
                  <Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 400,
                      color: '#121212',
                      opacity: 0.5,
                    }}
                    mr={1}
                  >
                    {date}
                  </Text>
                </div>
                <Flex mr={0} pr={2} css={markContainer}>
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                </Flex>
              </Flex>
            )}

            {mark <= 4.6 && (
              <Flex width="100%" p={3} pl={3} css={dateMarkContainer}>
                <div className="left-container" style={{ width: '50%' }}>
                  <Text className="testimonial-name" mr={1}>
                    {name}
                  </Text>
                  <Text mr={1}>{date}</Text>
                </div>
                <Flex mr={0} pr={2} css={markContainer}>
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" height={20} src="/img/star-new.png" />
                  <Image width={20} className="star" src="/img/star-new.png" />
                </Flex>
              </Flex>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default TestimonialBox;
