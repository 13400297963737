import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAppDownloadBtn } from '../../pages_/home/new_get_app_section/getapp.styles';
import { AboveTitle, FlexWrapper, FlexWrapperStart, PopupWrapper, RegularButton, Title, Wrapper } from '../../global/new-global-components';
import { Modal } from '../layout/Modal/Modal.component';
import { NeutralStringObjectI } from '../../types/NeutralObject.type';
import { gTagDataLayerPush } from '../../utils/gTagDataLayerPush.function';
import { COUNTRY } from '../../configs/env';

const onStoreBtnClick = (btn: string, elementPosition: number) => {
  const storeBtnClickEvents: NeutralStringObjectI = {
    playStore: 'web_play_store_button_click',
    appStore: 'web_app_store_button_click',
    huaweiStore: 'web_huawei_store_button_click',
  };

  gTagDataLayerPush({
    event: storeBtnClickEvents[btn],
    country: COUNTRY,
    pagePath: window.location.pathname,
    elementPosition: elementPosition,
    // timeSpentOnPage: '...' @TODO
  });

  console.log(`Triggered gTag data layer push in Popup -- elementPosition: ${elementPosition}`);
  console.log(`Triggered gTag data layer push for ${btn} - ${storeBtnClickEvents[btn]}`);
};

const Popup = (props: any) => {
  const { t } = useTranslation();
  const {
    iosLink = t('link_ios-user-app'),
    androidLink = t('link_android-user-app'),
    huaweiLink = t('link_huawei-user-app'),
    aboveTitle,
    title,
    showModal,
    setShowModal,
    elementPosition = 1,
  } = props;
  const [iconAppGallery, setIconAppGallery] = useState('/vectors/app-gallery.svg');
  const [iconAppStore, setIconAppStore] = useState('/vectors/app-store.svg');
  const [iconGooglePlay, setIconGooglePlay] = useState('/vectors/google-play.svg');

  return (
    <PopupWrapper>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Wrapper className="popup">
          <RegularButton style={{ color: 'white', cursor: 'pointer', fontSize: 24, float: 'right' }} onClick={() => setShowModal(false)}>
            X
          </RegularButton>
          {aboveTitle ? (
            <AboveTitle textAlign="left" color="white">
              {aboveTitle}
            </AboveTitle>
          ) : null}
          {title ? (
            <Title textAlign="left" color="white">
              {title}
            </Title>
          ) : null}
          <FlexWrapper>
            <FlexWrapperStart className="popup-buttons-wrapper">
              <GetAppDownloadBtn
                onMouseOver={() => setIconAppStore('/vectors/app-store-hovered.svg')}
                onMouseOut={() => setIconAppStore('/vectors/app-store.svg')}
                onClick={() => {
                  onStoreBtnClick('appStore', elementPosition);
                }}
                href={iosLink}
                target="_blank"
              >
                <img src={iconAppStore} />
              </GetAppDownloadBtn>
              <GetAppDownloadBtn
                onMouseOver={() => setIconGooglePlay('/vectors/google-play-hovered.svg')}
                onMouseOut={() => setIconGooglePlay('/vectors/google-play.svg')}
                onClick={() => {
                  onStoreBtnClick('playStore', elementPosition);
                }}
                href={androidLink}
                target="_blank"
              >
                <img src={iconGooglePlay} />
              </GetAppDownloadBtn>
              <GetAppDownloadBtn
                onMouseOver={() => setIconAppGallery('/vectors/app-gallery-hovered.svg')}
                onMouseOut={() => setIconAppGallery('/vectors/app-gallery.svg')}
                onClick={() => {
                  onStoreBtnClick('huaweiStore', elementPosition);
                }}
                href={huaweiLink}
                target="_blank"
              >
                <img src={iconAppGallery} />
              </GetAppDownloadBtn>
            </FlexWrapperStart>
          </FlexWrapper>
        </Wrapper>
      </Modal>
    </PopupWrapper>
  );
};

export default Popup;
