import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';

import { VOZZiWebsitePage } from '../../types/VozziWebsitePage.type';
import { HomePageLayout } from '../../layouts/HomePage.layout';
import { container } from '../../shared-styles/container.css';
import styled from '@emotion/styled';
import { COUNTRY } from '../../configs/env';

import { useTranslation } from 'react-i18next';
import { GetAppSection } from './new_get_app_section/getapp.section';
import { HeroSection } from './new_hero_section1/Hero.section';
import { HowItWorksSection } from './new_how_it_works_section/HowItWorks.section';
import { BenefitsSection } from './new_benefits_section/BenefitsSection';
import { countryLangs } from '../../configs/country-langs';
import { FaqSection } from './new_faq_section/FaqSection';
import { TestimonialsSection } from './new_testimonials_section/testimonials';
import { CoverageSection } from './new_coverage_section/coverage';
import { PressSection } from './new_press_section/press.section';
import { PressSection as PressSection2 } from './new_press_section1/press.section';
import { BundlesSection } from './new_bundles_section/bundles.section';
import { BlogSection } from './new_blog_section/blog.section';
import { getFeaturedImgForBlogLayout } from '../../helpers/getFeaturedImgForBlogLayout';
import { WhyVozziSection } from './new_why_vozzi_section/why-vozzi.section';
import './homepage.css';

// @ts-ignore
import { gsap, ScrollTrigger } from 'gsap/all';
import { BlogPostI } from 'pages_/blog/BlogPost.interface';
import { WpPostNodeI } from 'types/WpFeedData';
import { LanguageGuard } from '../../components/layout/LanguageGuard/LanguageGuard';

const HomePage: VOZZiWebsitePage = (props) => {
  const { vozziBundles, vozziTitles, domesticTitles } = props.pageContext.bundles;
  const [isLoading, setIsLoading] = useState(true);

  const { t, i18n } = useTranslation();
  const howItWorksRef = useRef(null);
  const tl = gsap.timeline({ repeat: 1, yoyo: true });

  const shapes = 'rect, circle, ellipse, polyline';

  const returnWpPostsByCountry = (edge: WpPostNodeI) => {
    if (COUNTRY === 'rs') {
      return edge.node.country === COUNTRY || !edge.node.country;
    } else {
      return edge.node.country === COUNTRY;
    }
  };

  const LANG = i18n.language;

  const metaOgImg = `/img/meta-img/vozzi-cover-photo--open-graph.jpg`;

  const meta = {
    title: t('meta_HomeTitle'),
    description: t('meta_HomeDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  const Container = styled.div`
    ${container}
  `;

  useEffect(() => {
    if (window.innerWidth > 768) {
      gsap.from('#hand-mobile', { x: 400, duration: 1, delay: 1 });
      gsap.from('#hand', { opacity: 0, duration: 0.6, delay: 0.8 });
    } else {
      gsap.from('#hand-mobile', { x: 400, duration: 1 });
      gsap.from('#hand', { opacity: 0, duration: 0.6, delay: 2 });
    }
    gsap.to('#zatrazi-izlazak-servisera', { opacity: 1, delay: 2, duration: 0.5 });
    gsap.to('#serviser-uskoro-stize', { opacity: 1, delay: 2.2, duration: 0.5 });

    // gsap.utils.toArray('.section').forEach((section: any) => {
    //   const sect = section;
    //   const elems = section.querySelectorAll('h2, h4');
    //   // Set starting params for sections
    //   gsap.set(elems, {
    //     y: 50,
    //     opacity: 0,
    //     duration: 1,
    //     ease: 'power3.out',
    //     overwrite: 'auto',
    //   });

    //   ScrollTrigger.create({
    //     trigger: section,
    //     start: '-700 top',
    //     end: 'bottom bottom',
    //     markers: false,
    //     onEnter: () =>
    //       gsap.to(elems, {
    //         y: 0,
    //         opacity: 1,
    //         stagger: 0.2,
    //       }),
    //   });
    // });
  }, []);

  const cLangs: any = countryLangs;

  const press: Array<BlogPostI> = props.data.allWordpressPost.edges
    .filter(
      (edge: WpPostNodeI) => edge.node.categories.filter((category) => category.slug === 'news' || category.slug === 'press').length > 0
    )
    .filter((edge: WpPostNodeI) => returnWpPostsByCountry(edge))
    .map((edge: WpPostNodeI) => {
      return {
        id: edge.node.id,
        title: edge.node.title,
        excerpt: edge.node.excerpt,
        featuredImg: getFeaturedImgForBlogLayout(edge.node),
        date: edge.node.date,
        url: `/${cLangs[COUNTRY]}/${t('news_Route')}/${edge.node.slug}`,
        categories: edge.node.categories,
      };
    });

  const posts: Array<BlogPostI> = props.data.allWordpressPost.edges
    .filter((edge: WpPostNodeI) => edge.node.categories.filter((category) => category.slug === 'blog').length > 0)
    .filter((edge: WpPostNodeI) => returnWpPostsByCountry(edge))
    .map((edge: WpPostNodeI, index: any) => {
      if (COUNTRY === 'bg') {
        return {
          key: index,
          id: edge.node.id,
          title: edge.node.title,
          excerpt: edge.node.excerpt,
          featuredImg: getFeaturedImgForBlogLayout(edge.node),
          date: edge.node.date,
          url: `/bg/blog/${edge.node.slug}`,
          categories: edge.node.categories,
        };
      } else {
        return {
          key: index,
          id: edge.node.id,
          title: edge.node.title,
          excerpt: edge.node.excerpt,
          featuredImg: getFeaturedImgForBlogLayout(edge.node),
          date: edge.node.date,
          url: `/sr/blog/${edge.node.slug}`,
          categories: edge.node.categories,
        };
      }
    });

  return (
    <HomePageLayout fullWidth={true} meta={meta} og={og}>
      <HeroSection />

      <HowItWorksSection />

      <BenefitsSection />
      <WhyVozziSection />
      <TestimonialsSection />
      <FaqSection />
      <GetAppSection
        side="left"
        mobileBackgroundImage="/new-images/getapp-background-mobile.png"
        backgroundImage="/new-images/getapp-background.png"
        elementPosition={3}
      />
      <CoverageSection />
      <LanguageGuard visible={[{ country: 'rs' }]}>
        <PressSection />
      </LanguageGuard>
      <LanguageGuard visible={[{ country: 'bg' }, { country: 'mk' }, { country: 'me' }, { country: 'bs' }]}>
        <PressSection2 posts={press} />
      </LanguageGuard>
      {/*<LanguageGuard hidden={[{ country: 'eu' }, { country: 'pl' }, { country: 'hu' }, { country: 'si' }]}>*/}
      {/*  <BundlesSection bundles={vozziBundles} vozziTitles={vozziTitles} domesticTitles={domesticTitles} />*/}
      {/*</LanguageGuard>*/}
      <LanguageGuard
        visible={[{ country: 'rs' }, { country: 'bg' }, { country: 'mk' }, { country: 'ba' }, { country: 'me' }, { country: 'si' }]}
      >
        <GetAppSection
          side="right"
          mobileBackgroundImage="/new-images/getapp-background-2-mobile.png"
          backgroundImage="/new-images/getapp-background-2.png"
          elementPosition={4}
        />
      </LanguageGuard>
      <LanguageGuard visible={[{ country: 'eu' }, { country: 'pl' }, { country: 'ro' }]}>
        <GetAppSection style={{ padding: '125px 0px', backgroundColor: 'lightGray', width: '100%' }} elementPosition={4} />
      </LanguageGuard>
      <LanguageGuard visible={[{ country: 'rs' }, { country: 'bg' }]}>
        <BlogSection posts={posts} />
      </LanguageGuard>
    </HomePageLayout>
  );
};

export const allPostsQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          slug
          path
          title
          excerpt
          status
          date
          template
          country
          format
          categories {
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
