import styled from '@emotion/styled';

export const HowItWorksWrapper = styled.div`
  #road-with-car {
    position: absolute;
    height: calc(100% + 180px);
    width: 100%;
    left: -75px;
    top: -50px;
    bottom: -90px;
  }

  .how-it-works-flex-wrapper {
    overflow: initial;
  }

  .how-it-works-section-title {
    max-width: 805px;
  }

  @media screen and (max-width: 370px) {
    h2 {
      font-size: 24px !important;
    }

    #phone {
      width: 150px;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    #road-with-car {
      left: 0px !important;
      height: 100% !important;
    }

    #phone {
      transform: scale(0.7) translateY(-250px) !important;
      left: 25px !important;
      bottom: 50px !important;
    }

    #how-it-works {
      padding: 0px 50px !important;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 50px 0 !important;
      align-items: flex-start !important;
    }

    .cards-wrapper {
      position: relative;
      top: -240px;
    }

    .card-wrapper {
      width: 360px !important;
      padding: 30px !important;
      height: auto;

      .card-title {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 28px;
      z-index: 18;
      position: relative;
    }

    h4 {
      font-size: 12px;
    }

    .how-it-works-section {
      padding: 0px;
    }

    .phone {
      width: 240px;
      margin: 0 auto;
    }

    .padding-20 {
      padding: 60px 20px;
    }

    .flex-col {
      display: block;
      width: 100%;
    }

    #how-it-works {
      position: relative;
      /* height: 700px; */
    }

    #road-with-car {
      position: absolute;
      height: auto;
      width: 300%;
      left: 25px;
      top: -20px;
      bottom: -20px;
    }

    #path {
      width: 400px !important;
    }

    .cards-wrapper {
      position: relative;
      top: -70px;
    }

    .overlay-wrapper::after {
      content: '';
      position: absolute;
      background-color: #e9e9eb;
      width: 120px;
      top: 0;
      height: 200px;
      mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      right: 0;
      z-index: 9;
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    #road-with-car {
      left: 25px !important;
      height: calc(100% + 140px) !important;
      width: 95% !important;
    }

    .padding-20 {
      padding: 60px 50px;
    }

    #phone {
      left: 100px;
      top: 225px;
    }

    .how-it-works-flex-wrapper {
      flex-direction: row;
    }

    .cards-wrapper {
      top: 0px;
    }
  }

  @media screen and (min-width: 769px) {
    #phone {
      transform: scale(0.9) translateY(-100px);
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1099px) {
    #road-with-car {
      left: -15px;
      height: 100%;
    }

    #phone {
      transform: scale(0.7) translateY(-250px);
      left: 80px;
    }

    #how-it-works {
      padding: 0px 50px;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 50px 0;
      align-items: flex-start;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1199px) {
    #road-with-car {
      left: -45px;
    }

    #phone {
      left: 130px;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 50px 0;
      align-items: flex-start;
    }
  }

  @media screen and (min-width: 1200px) {
    #how-it-works {
      padding: 0px 50px;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 50px 0;
      align-items: flex-start;
    }
  }

  @media screen and (min-width: 1400px) {
    #how-it-works {
      padding: 0px 100px;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 200px 0;
      align-items: flex-start;
    }
  }

  @media screen and (min-width: 1500px) {
    #how-it-works {
      padding: 0px 150px;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 150px 0;
      align-items: flex-start;
    }
  }

  @media screen and (min-width: 1601px) {
    #how-it-works {
      padding: 0px 200px;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 200px 0;
      align-items: flex-start;
    }
  }
  @media screen and (min-width: 2000px) {
    #road-with-car {
      height: 130%;
      width: 80%;
      top: -15%;
      bottom: -20%;
      position: absolute;
      left: 0;
    }

    #phone {
      top: 150px;
    }

    #how-it-works {
      padding: 0px 200px;
    }

    .how-it-works-flex-wrapper {
      padding: 0 0 300px 0;
      align-items: flex-start;
    }

    .how-it-works-section-title {
      max-width: 1000px;
    }
  }

  @media screen and (min-width: 2200px) {
    #phone {
      width: 600px;
    }
  }
`;

export const HowItWorksFirstSection = styled.div`
  top: 0;
  padding-bottom: 280px;
  padding-top: 120px;
`;

export const HowItWorksSecondSection = styled.div`
  padding: 150px 0;
  padding-bottom: 300px;
  padding-top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
