import styled from '@emotion/styled';

export const TestimonialsSectionWrapper = styled.div`
  .testimonials-slider .slick-arrow {
    /* background-image: 'url(/img/arrow-black@2x.png)'; */
    background-repeat: no-repeat;
    top: 50%;
    height: 16px;
    width: 24px;
    z-index: 2;
  }

  .slick-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slick-vertical .slick-arrow {
    position: unset;
  }
  .slick-vertical .slick-prev {
    float: unset;
    transform: rotate(0deg);
    margin-bottom: 15px;
  }
  .slick-vertical .slick-next {
    float: unset;
    transform: rotate(180deg);
    margin-top: 15px;
  }
  .slick-vertical .slick-slide {
    padding: 10px 0px;
    box-sizing: border-box;
  }

  .slick-vertical .slick-list {
    height: 550px !important;
  }

  .second-slider .slick-list {
    position: relative;
    padding-top: 10px;
  }

  .second-slider.slick-list * {
    font-family: Anek Latin;
    color: #121212;
    opacity: 0.5;
  }

  .second-slider .slick-slide {
    opacity: 0;
    width: 20px !important;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .second-slider .slick-current {
    color: #fe7f13;
    font-weight: bold;
  }

  .second-slider .slick-prev,
  .second-slider .slick-next {
    transform: none !important;
  }

  .second-slider .slick-prev {
    left: 40px !important;
  }

  .second-slider .slick-next {
    right: 40px !important;
  }

  .second-slider .slick-arrow {
    background-image: none;
    height: 24px;
    top: -2px !important;
  }

  .second-slider .slick-active {
    opacity: 1;
    width: 20px;
  }

  .testimonials-slider .slick-prev {
    float: right;
    left: -20px;
    transform: rotate(270deg);
  }
  .testimonials-slider .slick-next {
    float: left;
    right: -20px;
    transform: rotate(90deg);
  }

  .testimonials-first-slider {
    padding: 0px 0px 220px 0px;
    padding-left: 300px;
    padding-bottom: 0px;
    margin-bottom: 40px;
  }

  .testimonials-first-slider .slick-slide {
    max-width: 390px;
    width: 390px;
    margin-right: 60px;
  }

  .testimonials-first-slider .slick-list {
    overflow: hidden;
  }

  .testimonials-first-slider .slick-track {
    display: flex;
  }

  .testimonials-second-slider .slick-slide {
    width: 39px !important;
    opacity: 1;
  }

  .testimonials-second-slider .slick-slide > div {
    margin-right: 6px;
    margin-left: 0;
  }

  .testimonials-first-slider .slick-current + .slick-slide + .slick-slide + .slick-slide {
    opacity: 0.5;
    filter: grayscale(20);
  }

  .testimonials-second-slider .slick-slide * {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: #121212;
    opacity: 0.5;
  }

  .testimonials-second-slider {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .testimonials-second-slider .slick-track {
    display: flex;
    justify-content: center;
  }

  .testimonials-second-slider .slick-list {
    width: 200px;
    margin: 0px auto;
    overflow: hidden;
  }

  .testimonials-second-slider .slick-current > div {
    color: #121212 !important;
    opacity: 1 !important;
  }

  .testimonials-second-slider .slick-slide .slick-current div {
    color: #121212 !important;
    opacity: 1 !important;
  }

  .testimonials-slider .slick-slide {
    padding: 0 15px;
    box-sizing: border-box;
  }
  .testimonial-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
  .star {
    margin-right: 7px;
    :last-child {
      margin-right: 0;
    }
  }

  .testimonialsArrowsWrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;

    .slick-list {
      display: none;
    }
  }

  @media screen and (max-width: 449px) {
    .testimonialsArrowsWrapper {
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .testimonials-section {
      padding: 40px 20px;
    }
    h4 {
      font-size: 12px;
    }
    h2 {
      font-size: 28px;
    }
    .testimonial-name {
      font-size: 12px;
      font-weight: 600;
    }
    .slick-slide {
      max-width: 100%;
    }
    .star {
      margin-right: 1px;
      :last-child {
        margin-right: 0;
      }
    }

    .testimonials-first-slider {
      padding-left: 0;
    }

    .testimonials-first-slider .slick-slide {
      max-width: 255px;
      width: 255px;
      margin-right: 10px;
    }

    .testimonials-first-slider .slick-list {
      padding-left: 0 !important;
    }

    .testimonials-second-slider {
      width: 100%;
    }

    .testimonials-second-slider .slick-list {
      /* margin-top: 0; */
      max-width: 155px;
      margin: 0 auto;
      height: 36px;
      overflow: hidden;
    }

    .slick-vertical {
      flex-direction: row;
    }

    .testimonials-second-slider .slick-current div {
      color: #121212 !important;
      opacity: 1 !important;
    }
  }

  @media screen and (max-width: 370px) {
    .testimonials-second-slider .slick-list {
      visibility: hidden;
    }

    .testimonials-section {
      h2 {
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .testimonials-section {
      padding: 60px 50px 60px 50px;
    }
    .testimonials-first-slider .slick-slide {
      width: 390px;
      max-width: 390px;
    }

    .testimonials-second-slider .slick-list {
      visibility: hidden;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .testimonials-section {
      padding: 120px 50px 60px 50px;
    }
    .testimonials-first-slider {
      padding-left: 50px;
    }

    .testimonials-first-slider .slick-slide {
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 1400px) {
    .testimonials-section {
      padding: 120px 100px 60px 100px;
    }
    .testimonials-first-slider {
      padding-left: 100px;
    }

    .testimonialsArrowsWrapper {
      padding-right: 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .testimonials-section {
      padding: 120px 150px 60px 150px;
    }
    .testimonials-first-slider {
      padding-left: 150px;
    }

    .testimonialsArrowsWrapper {
      padding-right: 150px;
    }
  }

  @media screen and (min-width: 1601px) {
    .testimonials-section {
      padding: 120px 200px 60px 200px;
    }
    .testimonials-first-slider {
      padding-left: 200px;
    }

    .testimonialsArrowsWrapper {
      padding-right: 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .testimonials-section {
      padding: 120px 200px 60px 200px;
    }
    .testimonials-first-slider {
      padding-left: 200px;
    }

    .testimonials-first-slider .slick-slide {
      max-width: 420px;
      width: 420px;
    }

    .testimonials-first-slider .slick-current + .slick-slide + .slick-slide + .slick-slide {
      opacity: 1;
      filter: none;
    }

    .testimonials-first-slider .slick-current + .slick-slide + .slick-slide + .slick-slide + .slick-slide {
      opacity: 0.5;
      filter: grayscale(20);
    }
  }

  @media screen and (min-width: 2200px) {
    .testimonials-first-slider .slick-slide {
      max-width: 580px;
      width: 580px;
    }

    .testimonial-name {
      font-size: 26px;
      padding-left: 20px;
    }

    .left-container {
      width: 70% !important;
    }

    .star {
      width: 45px !important;
      height: 35px;
    }

    .testimonials-first-slider .slick-current + .slick-slide + .slick-slide + .slick-slide {
      opacity: 0.5;
      filter: grayscale(20);
    }

    .testimonials-second-slider {
      .slick-list {
        padding-top: 20px;
      }

      .slick-slide * {
        font-size: 26px;
      }
      .slick-prev,
      .slick-next {
        height: 86px !important;
        width: 86px !important;
      }
    }
  }
`;
